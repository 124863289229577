import React, { CSSProperties } from 'react';
import { RichText, ValRichText } from '@valbuild/next';
import Image from 'next/image';

export interface BodyProps {
  richText?: RichText<{
    block: {
      h1: true;
      h2: true;
      h3: true;
      h4: true;
      h5: true;
      h6: true;
      ul: true;
      ol: true;
    };
    inline: {
      a: true;
      img: true;
    };
    style: {
      bold: true;
      italic: true;
      lineThrough: true;
    };
  }>;
  className?: string;
  dark?: boolean;
  size?: 'small' | 'medium' | 'large' | 'extraLarge';
  children?: React.ReactNode | React.ReactNode[];
}

interface CustomCSSProperties extends CSSProperties {
  '--cross': string;
}

const Body: React.FC<BodyProps> = ({
  richText,
  children,
  className,
  dark,
  size,
}) => {
  var classes = '';
  switch (size) {
    case 'small':
      classes = 'text-base leading-blankLooser';
      break;
    case 'medium':
      classes = 'text-lg leading-blankRelaxed';
      break;
    case 'large':
      classes = 'text-xl leading-blankLoose';
      break;
    case 'extraLarge':
      classes =
        'laptop:text-plakatSmaller tablet:text-plakatSmallest text-xl leading-blankLoose';
      break;
    default:
      classes =
        'text-base leading-blankLooser tablet:text-lg tablet:leading-blankRelaxed laptop:text-xl laptop:leading-blankLoose';
  }

  const cn = `${
    dark ? 'text-blankYellow' : 'text-blankBlack'
  } font-sans break-words transition-50ms ${className} ${
    !className && classes
  }`;

  // Select correct colored icon for lists
  const style: CustomCSSProperties = {
    '--cross': dark ? "url('/icons/crossDark.svg')" : "url('/icons/cross.svg')",
  };
  if (richText) {
    return (
      <div className={cn} style={style}>
        <ValRichText
          theme={{
            bold: 'font-bold',
            a: null,
            italic: 'italic',
            lineThrough: 'line-through',
            ol: 'list-decimal pl-6',
            ul: null,
            h1: null,
            h2: null,
            h3: null,
            h4: null,
            h5: null,
            h6: null,
            img: null,
            li: null,
          }}
          transform={(node) => {
            if (typeof node !== 'string' && node.tag === 'img') {
              return (
                <Image
                  className="mt-24 laptop:w-[720px]"
                  src={node.src.url}
                  width={node.src.metadata?.width}
                  height={node.src.metadata?.height}
                  alt={''} // TODO: alt
                />
              );
            }
          }}
        >
          {richText}
        </ValRichText>
      </div>
    );
  }

  return (
    <p className={cn} style={style}>
      {children}
    </p>
  );
};

export default Body;
