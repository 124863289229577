import Link from 'next/link';
import { useState } from 'react';
import useMenuHighlighting from '../../hooks/useMenuHighlighting';
import { NavProps } from './Nav';

const AboutMobileNav = ({ chapters, chaptersRef }: NavProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [currentChapterSlug, chapterIndex] = useMenuHighlighting(chaptersRef);

  return (
    <div className="laptop:hidden bg-blankBlack flex flex-col w-full">
      <span
        className="flex flex-row items-center justify-between p-[30px]"
        onClick={() => setShowMenu(!showMenu)}
      >
        <h2 className="text-[24px] font-[500] font-solina">Innhold</h2>
        <button onClick={() => setShowMenu(!showMenu)}>
          {showMenu}
          <div className={` rotate-${showMenu ? '0' : '180'} duration-150`}>
            <svg
              width="24"
              height="14"
              viewBox="0 0 24 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.707 1.79295L1.37252 13.1275L0.66541 12.4204L11.9999 1.08585L12.707 1.79295Z"
                fill="#272D2A"
              />
              <path
                d="M23 13.5L11.5001 2.00012L12.2072 1.29302L23.7071 12.7929L23 13.5Z"
                fill="#272D2A"
              />
            </svg>
          </div>
        </button>
      </span>
      <div
        id="chapters"
        className={`${
          showMenu ? 'flex' : 'hidden'
        } flex-col gap-y-[15px] px-[30px] pb-[30px]`}
      >
        {chapters.map((chapter, idx) => (
          <div
            className={`${
              currentChapterSlug === chapter.slug &&
              'bg-blue-700 text-blankBlack'
            } w-fit`}
            key={idx}
            onClick={() => setShowMenu(!showMenu)}
          >
            <Link href={'#' + chapter.slug}>{chapter.header}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutMobileNav;
