import Body from '../components/Body/Body';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import MainNav from '../components/MainNav/MainNav';
import MobileNav from '../components/MobileNav/MobileNav';
import PageHeader from '../components/PageHeader/PageHeader';
import PageMetadata from '../components/PageMetadata/PageMetadata';
import StickyOverlay from '../containers/StickyOverlay';
import { useTheme } from '../hooks/useTheme';
import AboutMobileNav from '../components/Handboka/AboutMobileNav';
import ChapterHeader from '../components/Handboka/ChapterHeader';
import Nav from '../components/Handboka/Nav';
import Search from '../components/Handboka/Search';
import SectionHeader2 from '../components/Handboka/SectionHeader2';
import Head from 'next/head';
import { useEffect, useRef } from 'react';
import { useDarkSideContext } from '../Context/DarksideContext';
import { useViewportSizeTracking } from '../hooks/useViewportSizeTracking';
import ScrollToTopButton from '../components/ScrollToTopButton/ScrollToTopButton';
import handbookVal from '../content/pages/handboka.val';
import { useVal } from '../val/val.client';

const Index = () => {
  const { title, ingress, chapters, theme } = useVal(handbookVal);
  useViewportSizeTracking();
  const { darksideMode } = useDarkSideContext();
  useTheme(theme, 'aboutBlank');
  const chaptersRef = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    chaptersRef.current = chaptersRef.current.slice(0, chapters.length);
  }, [chapters]);

  const setChapterRef = (idx: number, el: HTMLDivElement) => {
    chaptersRef.current[idx] = el;
  };

  useEffect(() => {
    console.log(
      ` 
%c           G                  
          !@.                 
          &@?    7            
         ?@@&   P@            
         &@@@~ Y@@:           
        7@@@@G7@@@?           
        &@@@@@@@@@&           
        &@@@@@@@@@@~   ^:     
   :#   B@@@@@@@@@@B   #B     
   G@Y  5@@@@@@@@@@@. J@@^    
   &@@Y J@@@@@@@@@@@7.@@@B    
   @@@@7Y@@@@@@@@@@@&&@@@@.   
   &@@@@@@@@@@@YB@@@@@@@@@:   
   #@@@@@@@@@@~  B@@@@@@@@^   
   P@@@@@@@@@:    B@@@@@@@~   
   J@@@@@@@#.      #@@@@@@: .
   ^@@@@@@Y  :~!~. .#@@@@@:#Y 
~P !@@@@@^ Y@@@@@@&^.B@@@@&@! 
~@@&@@@#. #@@@@@@&@&. 5@@@@@! 
 &@@@@G  7@@@@@@5::JJ  5@@@@5 
 Y@@@5   5@@@@G:^^^?Y  .5@@@& 
 7@@7    B@@@?^~^^^7P .. G@@@.
 Y@!     &@5:^^::::~G .  :#@@7
 #~      Y^~^^^^^^^!G   ..:B@B
J! ......:::^^^^~^^~!^^~~~!?##
.........Kjøregår!...........
`,
      'background: #272D2A; color:  #FFFCB6'
    );
  }, []);

  return (
    <div
      id="aboutBlank"
      className="relative bg-blankYellow no-transition-on-load transition-bg-1s"
    >
      <PageMetadata
        title={'Personalhåndboka'}
        description={
          'Her finner det meste du trenger å vite om å jobbe i Blank. Alle ansatte kan foreslå endringer og delta i diskusjoner om våre rutiner og ordninger.'
        }
        url={'/handboka'}
      />
      <Head>
        <title>Håndboka | Blank</title>
        <meta name="description" content="Blank sin håndbok 2023" />
        <meta name="og:title" content="about:blank" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
      </Head>
      <Header forceDark>
        <MainNav />
        <MobileNav />
      </Header>
      <div className={`${darksideMode && 'hidden'}`}>
        <StickyOverlay>
          <PageHeader
            title={title}
            renderKaffepratLink={false}
            description={ingress}
            forceLineBreaks
          />
          <div className="relative h-fit">
            <div className="h-full w-0 absolute z-50 left-[20px] laptop:left-32">
              <Nav chapters={chapters} chaptersRef={chaptersRef} />
            </div>
            <section className={`w-full flex flex-col items-center mb-[80px]`}>
              <div className="relative laptop:space-y-0 space-y-[24px] laptop:max-w-[650px] flex flex-col items-center justify-center">
                <AboutMobileNav chapters={chapters} chaptersRef={chaptersRef} />
                <div className="sticky top-28 flex flex-col w-full gap-[30px] z-10">
                  <Search />
                </div>
                <div className="flex flex-col gap-y-[80px] px-[20px] laptop:px-[0px] searchable">
                  {chapters.map((chapter, idx) => (
                    <div
                      key={idx}
                      id={chapter.slug}
                      ref={(el) => setChapterRef(idx, el)}
                      className="scroll-mt-[170px]"
                    >
                      <div className="mb-[20px]">
                        <ChapterHeader
                          title={
                            idx === 0
                              ? chapter.sections[0].header
                              : chapter.header
                          }
                        />
                      </div>
                      <div className={`space-y-[60px] first:mt-0`}>
                        {chapter.sections.map((section, sectionIdx) => (
                          <div
                            key={sectionIdx}
                            className="flex flex-col h-fit"
                            id={section.slug}
                          >
                            {idx !== 0 && (
                              <SectionHeader2
                                title={section.header}
                                classes=" laptop:pt-[0px] text-[24px] tablet:text-[24px] laptop:text-[24px]"
                              />
                            )}
                            <Body
                              richText={section.text}
                              className=" text-blankBlack tracking-[3%] leading-[29.6px] text-[16px]  tablet:text-[18px] laptop:text-[18px] desktop:text-[18px] tablet:font-[200] mt-[-2px] pt-[0px]"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
          <ScrollToTopButton />
        </StickyOverlay>
        <Footer />
      </div>
    </div>
  );
};

export default Index;
