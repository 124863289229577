'use client';
import { useEffect, useState } from 'react';
import useScrollPosition from '../../hooks/useScrollPosition';
import DarksideToggle from '../DarksideToggle/DarksideToggle';
import MainNavItem from '../MainNavItem/MainNavItem';
import { usePathname, useRouter } from 'next/navigation';
import headerVal from '../../content/header.val';
import { useVal } from '../../val/val.client';

interface MainNavProps {
  storyBook?: boolean;
}

const MainNav = ({ storyBook = false }: MainNavProps) => {
  const { navigationItems: navItems } = useVal(headerVal);
  const pathname = usePathname();

  const [overrideShowMenu, setOverrideShowMenu] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [lastScrollPosition, setLastScrollPos] = useState<number>(0);

  const scrollposition = useScrollPosition();

  useEffect(() => {
    const deltaY = scrollposition - lastScrollPosition;
    if (deltaY < 0 && overrideShowMenu) {
      setOverrideShowMenu(false);
    }
    setLastScrollPos(scrollposition);
    setShowMenu(scrollposition !== 0 && deltaY > 0);
  }, [scrollposition]);

  return (
    <nav className="hidden laptop:flex flex-row space-x-[52px]">
      <div
        className={`flex flex-row items-center space-x-[52px] bg-blankYellow transition-bg ease-in-out duration-300 ${
          showMenu && !overrideShowMenu
            ? 'translate-x-[1000px] pointer-events-none'
            : 'translate-x-0 pointer-events-auto'
        }`}
      >
        {navItems.map((item, i) => {
          switch (item.type) {
            case 'link':
              return (
                <MainNavItem
                  key={i}
                  isSelected={pathname?.includes(item.url)}
                  className={
                    pathname?.includes(item.url) &&
                    'text-blankYellow transition-50ms'
                  }
                  href={item.url}
                >
                  {item.title}
                </MainNavItem>
              );
            case 'contactButton':
              return (
                <MainNavItem
                  key={i}
                  customLink
                  onClick={() => {
                    setOverrideShowMenu(true);
                    document.getElementById('contactUs')?.scrollIntoView();
                  }}
                  onKeyDown={() => {}}
                  role="button"
                >
                  {item.text}
                </MainNavItem>
              );
            default:
              const exhaustiveCheck: never = item;
              console.error('Unhandled item type', exhaustiveCheck);
              return null;
          }
        })}
      </div>
      <button
        className="relative w-32 h-32 pointer-events-auto"
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        <div
          className={`h-[1px] w-32 rounded-full bg-blankBlack transition ease transform duration-300 mb-[18px] ${
            showMenu ? '' : 'rotate-45 translate-y-[9.5px]'
          }`}
        />
        <div
          className={`h-[1px] w-32 rounded-full bg-blankBlack transition ease transform duration-300 ${
            showMenu ? '' : '-rotate-45 -translate-y-[9.5px]'
          }`}
        />
      </button>
      {!storyBook && <DarksideToggle />}
    </nav>
  );
};

export default MainNav;
