import Image from 'next/legacy/image';

interface MobileNavSoMeLinkProps {
  href: string;
  self?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const MobileNavSoMeLink: React.FC<MobileNavSoMeLinkProps> = ({
  children,
  href,
  self,
}) => {
  return (
    <div className="flex justify-end">
      {self ? (
        <a
          href={href}
          className="font-solina text-[28px] tablet:text-respiraSmall leading-blankSnug tracking-blankTight text-[#FFFCB6] mb-8 last:mb-0 underline mr-8"
          target={'_self'}
        >
          {children}
        </a>
      ) : (
        <a
          href={href}
          className="font-solina text-[28px] tablet:text-respiraSmall leading-blankSnug tracking-blankTight text-[#FFFCB6] mb-8 last:mb-0 underline mr-8"
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          {children}
        </a>
      )}
      <Image src="/icons/arrow.svg" alt="arrow" height={11} width={32} />
    </div>
  );
};

export default MobileNavSoMeLink;
