import classNames from 'classnames';
import Link from 'next/link';

interface MobileNavLinkProps {
  className?: string;
  href: string;
  linkStyle?: string;
  onClick: () => void;
  outgoing?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const MobileNavLink: React.FC<MobileNavLinkProps> = ({
  children,
  className,
  linkStyle = 'respira',
  href,
  onClick,
  outgoing,
}) => {
  const classes = classNames({
    'text-lg tracking-blankWider leading-[22px] py-[18px] uppercase no-underline font-semibold':
      linkStyle === 'plakat',

    'font-respira text-[40px] tablet:text-respiraSmall leading-blankSnug tracking-blankTight':
      linkStyle === 'respira',

    'font-solina text-[40px] tablet:text-plakatSmall leading-blankSnug tracking-blankTight':
      linkStyle === 'plakatLarge',
  });

  return outgoing ? (
    <a
      className={`${classes} text-[#FFFCB6] mb-8 last:mb-0 w-fit text-right`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <Link
      href={href}
      className={`${classes} ${className} text-[#FFFCB6] mb-8 last:mb-0 text-right`}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default MobileNavLink;
