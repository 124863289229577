import { FC, Fragment } from 'react';
import WideLayout from '../../containers/WideLayout';
import Body from '../Body/Body';
import ImageHero from '../Hero/ImageHero';
import SvgIllustration from '../Illustration/SvgIllustration';
import KaffepratLink from '../KaffepratLink/KaffepratLink';
import { ArrowDark } from '../SVGs';
import { Image } from '@valbuild/next';

export interface PageHeaderProps {
  title: string;
  description?: string;
  imgSrc?: Image;
  alt?: string;
  renderKaffepratLink?: boolean;
  eventSeries?: string;
  layout?: string;
  forceLineBreaks?: boolean;
  extraPaddingRight?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  description,
  imgSrc,
  alt,
  renderKaffepratLink = true,
  eventSeries,
  layout,
  forceLineBreaks = false,
  children,
  extraPaddingRight,
}) => {
  return (
    <>
      {imgSrc ? (
        <ImageHero
          imgSrc={imgSrc}
          title={title}
          alt={alt}
          eventSeries={eventSeries}
          header
          layout={layout}
          description={description}
          extraPaddingRight={extraPaddingRight}
        />
      ) : (
        <WideLayout className="mb-[20px]">
          <div
            className={`flex flex-col laptop:flex-row laptop:justify-between w-full text-blankBlack relative mt-[135px] laptop:mt-[236px] bottom-0 laptop:max-w-[1260px] desktop:max-w-[1260px] box-border mx-[20px] tablet:mx-[20px] laptop:mx-[50px] desktop:mx-[150px] gap-[10px]`}
          >
            <div
              className={`flex flex-col all-but-last-child:mb-[28px] laptop:all-but-last-child:mb-[32px] tablet:max-w-[700px] ${
                extraPaddingRight && 'laptop:pr-[100px]'
              }`}
            >
              <h1 className="font-respira font-[400] tracking-[0.01em] leading-[104%] text-[44px] tablet:text-[60px] laptop:text-[100px] mb-[46px]">
                {forceLineBreaks
                  ? title.split('&shy;').map((part, idx) => {
                      if (idx !== title.split('&shy;').length - 1)
                        return <span key={idx}>{part}&shy;</span>;
                      else return <Fragment key={idx}>{part}</Fragment>;
                    })
                  : title}
              </h1>
              {description && (
                <Body className="text-fluidIngress">{description}</Body>
              )}

              {renderKaffepratLink && (
                <div className="hidden laptop:flex flex-col pt-5 w-fit right-0 top-0 self-end absolute tablet:translate-x-[0] laptop:translate-x-[0] mr-[-4px] tablet:mr-[24px] laptop:mr-[24px] overflow-clip">
                  <KaffepratLink className="w-[178px] h-[178px] tablet:w-[310px] tablet:h-[310px] laptop:w-[310px] laptop:h-[310px]">
                    <SvgIllustration>
                      <ArrowDark className="w-[42px] h-[14px] laptop:w-[70px] laptop:h-24" />
                    </SvgIllustration>
                  </KaffepratLink>
                </div>
              )}
            </div>
            {children && children}
          </div>
        </WideLayout>
      )}
    </>
  );
};

export default PageHeader;
