import { s, c, t } from '../val.config';

export const schema = s.array(s.object({ pictures: s.array(s.image()) }));

export type DarkSide = t.inferSchema<typeof schema>[0];

export default c.define('/content/darkside.val.ts', schema, [
  {
    pictures: [
      c.image(
        '/public/val/images/d16b762bb1d4d4169f0bc73c5ba20bed7ddcfd48-769x483.gif',
        {
          width: 769,
          height: 483,
          mimeType: 'image/gif',
        }
      ),
      c.image(
        '/public/val/images/b92675aa94d0be5a1b6a4462a161f964b0fc994a-767x475.jpg',
        {
          width: 767,
          height: 475,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/1786900646cf93c1c8776a43cc5e6b5a99d75380-769x478.jpg',
        {
          width: 769,
          height: 478,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/f224b5c822d70774292244ed960190c86930351d-800x533.jpg',
        {
          width: 800,
          height: 533,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/59dccf0e6894b79beeff93fb4533ab30a122b368-769x479.jpg',
        {
          width: 769,
          height: 479,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/a0e565ddc3bd4ecf6426247cf2490f7807311174-769x479.jpg',
        {
          width: 769,
          height: 479,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/6a50fbedb88dc54741bb4d7b9ac0a2cee831a2c6-672x732.gif',
        {
          width: 672,
          height: 732,
          mimeType: 'image/gif',
        }
      ),
      c.image(
        '/public/val/images/ec06cf1adfd032051ce3573df8f2ad0e37f2cc64-770x482.jpg',
        {
          width: 770,
          height: 482,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/5f7792d782afe55df48f8567803ff198e5929486-900x600.jpg',
        {
          width: 900,
          height: 600,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/b1aa6ab1263f31c3a45fe331a53f1207d8da2001-768x481.jpg',
        {
          width: 768,
          height: 481,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/1c1e024ce34b24a871c40380012ea2e2462a7979-900x678.gif',
        {
          width: 900,
          height: 678,
          mimeType: 'image/gif',
        }
      ),
      c.image(
        '/public/val/images/fd663f7884ea244c7f3e40f4c4061602cec67f76-766x476.jpg',
        {
          width: 766,
          height: 476,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/765f6f138f9097d8342fc184630a9b6b4091f5b2-770x481.jpg',
        {
          width: 770,
          height: 481,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/67a72edd805f3fd4a384ee54120facd1b1523da2-770x484.jpg',
        {
          width: 770,
          height: 484,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/b5df69687309ea021eddf9b2598fc19f986be124-772x480.jpg',
        {
          width: 772,
          height: 480,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/b1aa6ab1263f31c3a45fe331a53f1207d8da2001-768x481.jpg',
        {
          width: 768,
          height: 481,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/ec06cf1adfd032051ce3573df8f2ad0e37f2cc64-770x482.jpg',
        {
          width: 770,
          height: 482,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/4a38aea4e3d2e6fe4434b042f3d4a113ccac7d1e-768x1024.jpg',
        {
          width: 768,
          height: 1024,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/e96be64a8a265e1a3477f47958dd8ad774bc8ba7-851x579.webp',
        {
          width: 851,
          height: 579,
          mimeType: 'image/webp',
        }
      ),
      c.image(
        '/public/val/images/a0e565ddc3bd4ecf6426247cf2490f7807311174-769x479.jpg',
        {
          width: 769,
          height: 479,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/1786900646cf93c1c8776a43cc5e6b5a99d75380-769x478.jpg',
        {
          width: 769,
          height: 478,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/dd1a00d09105a48318f07f6fe59b62c2fda55686-3601x3006.jpg',
        {
          width: 3601,
          height: 3006,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/7bcbb7d374d7568df1266fe5ac3c4bec805733f0-600x684.jpg',
        {
          width: 600,
          height: 684,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/59dccf0e6894b79beeff93fb4533ab30a122b368-769x479.jpg',
        {
          width: 769,
          height: 479,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/4f1002b09919822ecdbd67be8c3349189bb08400-768x477.jpg',
        {
          width: 768,
          height: 477,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/c317b63ccc992fe212ab4d178e917bfb9a22f87c-722x1089.jpg',
        {
          width: 722,
          height: 1089,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/6019d57c37c62ffaf81ed8210f39a69a6ac9bad3-768x476.jpg',
        {
          width: 768,
          height: 476,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/a9f9e285efd8ba6a8146e663023574d08075bde9-2048x1365.jpg',
        {
          width: 2048,
          height: 1365,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/6d059cc4be5eb0457721c4cf72653422c3fa9e54-3024x4032.jpg',
        {
          width: 3024,
          height: 4032,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/f414b152cb49a65509b019df0c4ebf1f5db1ce85-2048x1638.jpg',
        {
          width: 2048,
          height: 1638,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/5cddbe34565f434a6e1cb21f8a012b79395a1bd7-2048x1365.jpg',
        {
          width: 2048,
          height: 1365,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/c2ca1d180f4df9e7a1877c728976aff2f3e0d5f2-4032x3024.jpg',
        {
          width: 4032,
          height: 3024,
          mimeType: 'image/jpeg',
        }
      ),
    ],
  },
  {
    pictures: [
      c.image(
        '/public/val/images/d16b762bb1d4d4169f0bc73c5ba20bed7ddcfd48-769x483.gif',
        {
          width: 769,
          height: 483,
          mimeType: 'image/gif',
        }
      ),
      c.image(
        '/public/val/images/b92675aa94d0be5a1b6a4462a161f964b0fc994a-767x475.jpg',
        {
          width: 767,
          height: 475,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/1786900646cf93c1c8776a43cc5e6b5a99d75380-769x478.jpg',
        {
          width: 769,
          height: 478,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/f224b5c822d70774292244ed960190c86930351d-800x533.jpg',
        {
          width: 800,
          height: 533,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/59dccf0e6894b79beeff93fb4533ab30a122b368-769x479.jpg',
        {
          width: 769,
          height: 479,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/a0e565ddc3bd4ecf6426247cf2490f7807311174-769x479.jpg',
        {
          width: 769,
          height: 479,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/6a50fbedb88dc54741bb4d7b9ac0a2cee831a2c6-672x732.gif',
        {
          width: 672,
          height: 732,
          mimeType: 'image/gif',
        }
      ),
      c.image(
        '/public/val/images/ec06cf1adfd032051ce3573df8f2ad0e37f2cc64-770x482.jpg',
        {
          width: 770,
          height: 482,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/5f7792d782afe55df48f8567803ff198e5929486-900x600.jpg',
        {
          width: 900,
          height: 600,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/b1aa6ab1263f31c3a45fe331a53f1207d8da2001-768x481.jpg',
        {
          width: 768,
          height: 481,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/1c1e024ce34b24a871c40380012ea2e2462a7979-900x678.gif',
        {
          width: 900,
          height: 678,
          mimeType: 'image/gif',
        }
      ),
      c.image(
        '/public/val/images/fd663f7884ea244c7f3e40f4c4061602cec67f76-766x476.jpg',
        {
          width: 766,
          height: 476,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/765f6f138f9097d8342fc184630a9b6b4091f5b2-770x481.jpg',
        {
          width: 770,
          height: 481,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/67a72edd805f3fd4a384ee54120facd1b1523da2-770x484.jpg',
        {
          width: 770,
          height: 484,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/b5df69687309ea021eddf9b2598fc19f986be124-772x480.jpg',
        {
          width: 772,
          height: 480,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/b1aa6ab1263f31c3a45fe331a53f1207d8da2001-768x481.jpg',
        {
          width: 768,
          height: 481,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/ec06cf1adfd032051ce3573df8f2ad0e37f2cc64-770x482.jpg',
        {
          width: 770,
          height: 482,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/4a38aea4e3d2e6fe4434b042f3d4a113ccac7d1e-768x1024.jpg',
        {
          width: 768,
          height: 1024,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/e96be64a8a265e1a3477f47958dd8ad774bc8ba7-851x579.webp',
        {
          width: 851,
          height: 579,
          mimeType: 'image/webp',
        }
      ),
      c.image(
        '/public/val/images/a0e565ddc3bd4ecf6426247cf2490f7807311174-769x479.jpg',
        {
          width: 769,
          height: 479,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/1786900646cf93c1c8776a43cc5e6b5a99d75380-769x478.jpg',
        {
          width: 769,
          height: 478,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/dd1a00d09105a48318f07f6fe59b62c2fda55686-3601x3006.jpg',
        {
          width: 3601,
          height: 3006,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/7bcbb7d374d7568df1266fe5ac3c4bec805733f0-600x684.jpg',
        {
          width: 600,
          height: 684,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/59dccf0e6894b79beeff93fb4533ab30a122b368-769x479.jpg',
        {
          width: 769,
          height: 479,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/4f1002b09919822ecdbd67be8c3349189bb08400-768x477.jpg',
        {
          width: 768,
          height: 477,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/c317b63ccc992fe212ab4d178e917bfb9a22f87c-722x1089.jpg',
        {
          width: 722,
          height: 1089,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/6019d57c37c62ffaf81ed8210f39a69a6ac9bad3-768x476.jpg',
        {
          width: 768,
          height: 476,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/a9f9e285efd8ba6a8146e663023574d08075bde9-2048x1365.jpg',
        {
          width: 2048,
          height: 1365,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/6d059cc4be5eb0457721c4cf72653422c3fa9e54-3024x4032.jpg',
        {
          width: 3024,
          height: 4032,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/f414b152cb49a65509b019df0c4ebf1f5db1ce85-2048x1638.jpg',
        {
          width: 2048,
          height: 1638,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/5cddbe34565f434a6e1cb21f8a012b79395a1bd7-2048x1365.jpg',
        {
          width: 2048,
          height: 1365,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/c2ca1d180f4df9e7a1877c728976aff2f3e0d5f2-4032x3024.jpg',
        {
          width: 4032,
          height: 3024,
          mimeType: 'image/jpeg',
        }
      ),
      c.image(
        '/public/val/images/ac61b04793b089a3d19393f29f5d4399019e394e-600x1100.gif',
        {
          width: 600,
          height: 1100,
          mimeType: 'image/gif',
        }
      ),
    ],
  },
]);
