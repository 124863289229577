import React, { useEffect, useState } from 'react';

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const viewportHeight = window.innerHeight;
      setShowButton(scrollY >= viewportHeight * 0.5);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {showButton && (
        <button
          className="fixed bottom-7 right-5 p-[2px] bg-blankBlack text-blankYellow z-10"
          onClick={scrollToTop}
        >
          <svg
            width="24"
            height="14"
            viewBox="0 0 24 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.707 1.79295L1.37252 13.1275L0.66541 12.4204L11.9999 1.08585L12.707 1.79295Z"
              fill="#272D2A"
            />
            <path
              d="M23 13.5L11.5001 2.00012L12.2072 1.29302L23.7071 12.7929L23 13.5Z"
              fill="#272D2A"
            />
          </svg>
        </button>
      )}
    </>
  );
};

export default ScrollToTopButton;
