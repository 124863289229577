import { MutableRefObject, useEffect, useState } from 'react';

type ChapterInfo = [height: number, slug: string];

const findCurrentChapter = (
  chapters: Array<ChapterInfo>
): [chapterSlug: string, index: number] => {
  let scrollVal = window.scrollY;
  if (scrollVal < chapters[0][0] - 1) {
    return [null, -1];
  }

  let end = chapters.length - 1;
  for (let i = 0; i < end; i++) {
    let chapter = chapters[i];
    if (
      chapter[0] - 140 <= window.scrollY &&
      window.scrollY < chapters[i + 1][0] - 140
    ) {
      return [chapter[1], i];
    }
  }

  return [chapters[end][1], end];
};

const useMenuHighlighting = (
  chaptersRef: MutableRefObject<HTMLDivElement[]>
): [characterSlug: string, index: number] => {
  const [currentChapter, setCurrentChapter] = useState<[string, number]>([
    null,
    -1,
  ]);

  useEffect(() => {
    let chapterTuples: Array<ChapterInfo> = chaptersRef.current.map((c) => [
      c.offsetTop,
      c.id,
    ]);
    const handleEvent = () => {
      let newCurrentChapter = findCurrentChapter(chapterTuples);
      if (newCurrentChapter[1] !== currentChapter[1]) {
        setCurrentChapter(newCurrentChapter);
      }
    };
    window.addEventListener('scroll', handleEvent, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleEvent);
    };
  }, [chaptersRef, currentChapter]);

  return currentChapter;
};

export default useMenuHighlighting;
