import useMenuHighlighting from '../../hooks/useMenuHighlighting';
import Link from 'next/link';
import { MutableRefObject } from 'react';

export interface NavProps {
  chapters: { slug: string; header: string }[];
  chaptersRef: MutableRefObject<HTMLDivElement[]>;
}

const Nav = ({ chapters, chaptersRef }: NavProps) => {
  const [currentChapterSlug] = useMenuHighlighting(chaptersRef);

  return (
    <div
      className="hidden laptop:block desktop:block sticky top-28 left-0 bg-blankBlack transition-bg-1s w-[218px] h-fit px-[30px] py-[30px]"
      id="nav"
    >
      <div className={`flex flex-col transition-50ms`}>
        <h2 className="text-solina font-[500] text-[24px] leading-[28.8px] mb-[20px] tracking-[1%]">
          Innhold
        </h2>
        <div
          className={`bg-blankBlack font-[200] text-[16px] flex flex-col justify-items-start gap-y-[15px] text-solina`}
        >
          {chapters.map((chapter, idx) => (
            <div
              className={`${
                currentChapterSlug === chapter.slug &&
                'bg-blue-700 text-blankBlack'
              } w-fit`}
              key={idx}
            >
              <Link href={'#' + chapter.slug}>{chapter.header}</Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Nav;
