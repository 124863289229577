'use client';
import React, { useEffect, useState } from 'react';
import SpinningLink from '../SpinningLink/SpinningLink';
import {
  BubbleTea,
  Chagaccino,
  FlatWhite,
  Horchata,
  MangoLassi,
  MatchaLatte,
  OolongTea,
  Ramlosa,
  WaterKefir,
} from '../SVGs';

interface KaffepratLinkProps {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}
const drinks = [
  BubbleTea,
  Chagaccino,
  FlatWhite,
  Horchata,
  MangoLassi,
  MatchaLatte,
  OolongTea,
  Ramlosa,
  WaterKefir,
];

const KaffepratLink: React.FC<KaffepratLinkProps> = ({
  className,
  children,
}) => {
  const [randomDrinkIndex, setRandomDrinkIndex] = useState(0);

  useEffect(() => {
    setRandomDrinkIndex(Math.floor(Math.random() * drinks.length));
  }, []);

  return (
    <SpinningLink
      SpinningImage={drinks[randomDrinkIndex]}
      className={`z-10 ${className}`}
      href="/kaffeprat"
    >
      {children}
    </SpinningLink>
  );
};

export default KaffepratLink;
