import React from 'react';
import BlankLink from '../BlankLink/BlankLink';

export interface MainNavItemProps {
  className?: string;
  customLink?: boolean;
  href?: string;
  onClick?: () => void;
  onKeyDown?: () => void;
  role?: string;
  isSelected?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const MainNavItem: React.FC<MainNavItemProps> = ({
  className,
  customLink,
  children,
  href,
  onClick,
  onKeyDown,
  role,
  isSelected = false,
}) => {
  const cn = `${className} text-blankBlack font-sans text-base text-normal leading-normal tracking-blankTight underline last:mr-0 transition-50ms`;
  if (!!customLink) {
    if (!!href) {
      return (
        <BlankLink
          href={href}
          newTab={true}
          className={cn}
          isSelected={isSelected}
        >
          {children}
        </BlankLink>
      );
    } else {
      return (
        <BlankLink
          isSelected={isSelected}
          className={cn}
          role={role}
          onClick={() => !!onClick && onClick()}
          onKeyDown={() => !!onKeyDown && onKeyDown()}
        >
          {children}
        </BlankLink>
      );
    }
  } else {
    return (
      <BlankLink href={href} className={cn} link={true} isSelected={isSelected}>
        {children}
      </BlankLink>
    );
  }
};

export default MainNavItem;
