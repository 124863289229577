const SectionHeader2 = ({
  title,
  classes
}: {
  title: string;
  classes?: string;
}) => {
  return (
      <div
        className={`text-blankBlack font-[500] font-solina tracking-[5%] leading-[49px] transition-bg-1s h-fit text-[28px] `+ classes}
      >
        {title}
      </div>
  );
};

export default SectionHeader2;
