import Link from 'next/link';

interface SpinningLinkProps {
  className?: string;
  href: string;
  SpinningImage: React.FC<{ color?: string; className?: string }>;
  children?: React.ReactNode | React.ReactNode[];
}

const SpinningLink: React.FC<SpinningLinkProps> = ({
  children,
  className,
  href,
  SpinningImage,
}) => {
  return (
    <Link href={href} className="hover:bg-transparent">
      <div className="relative">
        <SpinningImage
          color="var(--dark)"
          className={`h-full w-full animate-spin transition-50ms ${className}`}
        />
        <div className="absolute z-10 top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
          {children}
        </div>
      </div>
    </Link>
  );
};

export default SpinningLink;
