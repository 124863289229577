import Mark from 'mark.js';
import { useEffect, useState } from 'react';

interface SearchProps {}

const Search = ({}: SearchProps) => {
  const [search, setSearch] = useState<string>('');
  const [matches, setMatches] = useState<HTMLElement[]>(null);
  const [matchInFocus, setMatchInFocus] = useState<number>(0);

  useEffect(() => {
    const markInstance = new Mark('div.searchable');
    markInstance.unmark({
      done: () => {
        markInstance.mark(search);
        const newMatches = Array.from(document.getElementsByTagName('mark'));
        setMatches(newMatches);
        const firstMatch = newMatches.length > 0 ? 0 : -1;
        setMatchInFocus(firstMatch);
        if (firstMatch !== -1) {
          highlightMatch(newMatches[firstMatch]);
          window.scrollTo(0, newMatches[0].offsetTop);
        }
      },
    });
  }, [search]);

  useEffect(() => {
    if (matchInFocus !== -1 && matches && matches.length > 0) {
      window.scrollTo(0, matches[matchInFocus].offsetTop);
    }
  }, [matchInFocus]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && search && matches && matches.length > 0) {
        if (e.shiftKey) {
          moveToPreviousMatch();
        } else {
          moveToNextMatch();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [matchInFocus, matches]);

  const highlightMatch = (match: HTMLElement) => {
    match.classList.add('border', 'p-1');
  };

  const unHighlightMatch = (match: HTMLElement) => {
    match.classList.remove('border', 'p-1');
  };

  const moveToNextMatch = () => {
    const nextMatchIndex = (matchInFocus + 1) % matches?.length;
    setMatchInFocus(nextMatchIndex);
    highlightMatch(matches[nextMatchIndex]);
    unHighlightMatch(matches[matchInFocus]);
  };

  const moveToPreviousMatch = () => {
    const previousMatchIndex =
      matchInFocus - 1 < 0 ? matches?.length - 1 : matchInFocus - 1;
    setMatchInFocus(previousMatchIndex);
    highlightMatch(matches[previousMatchIndex]);
    unHighlightMatch(matches[matchInFocus]);
  };

  return (
    <div
      className={`sticky bg-blankYellow flex flex-row w-full gap-[20px] px-[20px] laptop:px-0 pb-[20px] z-10`}
    >
      <div className="relative w-full flex flex-col gap-y-6">
        <input
          onChange={(e) => setSearch(e.target.value)}
          type="search"
          className={`py-2 text-blankBlack bg-blankYellow border border-blankBlack px-[10px] focus:outline-none italic placeholder-blankBlack focus:bg-blankBlack focus:placeholder-blankYellow focus:text-blankYellow w-full group bg-[url('/icons/magnifyingGlass.svg')] bg-no-repeat bg-[98%_6px]`}
          placeholder="Finn noe"
        />
        {search && (
          <div className="flex flex-row gap-[20px] justify-between items-center mb-2">
            {matchInFocus !== -1 && (
              <button onClick={moveToPreviousMatch}>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 3.19995L1 12.7M1 12.7L10.5 22.2M1 12.7H23.5"
                    stroke="#FFFCB6"
                  />
                </svg>
              </button>
            )}

            <div className="text-blankBlack whitespace-nowrap">
              {matches.length
                ? `${matchInFocus + 1}/${matches?.length}`
                : 'Ingen treff for søket'}
            </div>
            {matchInFocus !== -1 && (
              <button onClick={moveToNextMatch}>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 21.2L23 11.7M23 11.7L13.5 2.19995M23 11.7L0.499999 11.7"
                    stroke="#FFFCB6"
                  />
                </svg>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
