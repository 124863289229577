import { useEffect } from 'react';

const useModal = (modalOpen: boolean) => {
  useEffect(() => {
    const prev = document.body.style.overflow;
    if (modalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = prev;
    };
  }, [modalOpen]);
};

export default useModal;
