import classNames from 'classnames';
export interface SectionHeaderProps {
  className?: string;
  link?: boolean;
  setDefaultColor?: boolean;
  variant:
    | 'plakatSmallest'
    | 'plakatSmaller'
    | 'plakatSmall'
    | 'plakatLarge'
    | 'plakatXl'
    | 'respira'
    | 'respiraSmall'
    | 'respiraLarge';
  transparentBackground?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  pageheader?: boolean;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  children,
  className,
  link,
  setDefaultColor = true,
  variant,
  transparentBackground = false,
  pageheader = false,
}) => {
  const defaultColor = setDefaultColor ? 'text-blankBlack' : 'text-blankYellow';

  const classes = classNames({
    'font-plakat text-plakatSmallest leading-blankTighter tracking-blankTighter':
      variant === 'plakatSmallest',
    'font-plakat text-plakatSmaller leading-blankTighter tracking-blankTighter uppercase':
      variant === 'plakatSmaller',
    'font-plakat text-plakatSmall leading-blankTighter tracking-blankTighter uppercase':
      variant === 'plakatSmall',
    'font-plakat  laptop:text-plakatLarge tablet:text-plakatSmall text-plakatSmaller leading-blankTighter tracking-blankTighter uppercase':
      variant === 'plakatLarge',
    'font-plakat  text-plakatXl leading-blankTighter tracking-blankTighter uppercase':
      variant === 'plakatXl',
    'font-respira text-respiraSmall laptop:text-respiraLarge leading-blankSnug tracking-blankTight':
      variant === 'respira',
    'font-respira text-respiraSmall leading-blankSnug tracking-blankTight':
      variant === 'respiraSmall',
    'font-respira laptop:text-respiraLarge tablet:text-respiraLarge text-respiraMobile leading-blankTight tracking-blankTight':
      variant === 'respiraLarge',
  });

  return pageheader ? (
    <h1
      className={`relative break-words w-fit ${
        link && 'children:hover:bg-transparent'
      }`}
    >
      <div
        className={`absolute opacity-90 transition-50ms ${className} ${classes}  ${defaultColor}`}
      >
        {children}
      </div>
      <div className={`${className} no-underline`}>
        <span
          className={`${
            transparentBackground ? 'bg-transparent' : 'bg-blankYellow'
          } transition-bg-1s ${className} ${classes} text-transparent`}
        >
          {children}
        </span>
      </div>
    </h1>
  ) : (
    <h2
      className={`relative break-words w-fit ${
        link && 'children:hover:bg-transparent'
      }`}
    >
      <div
        className={`absolute opacity-90 transition-50ms ${className} ${classes}  ${defaultColor}`}
      >
        {children}
      </div>
      <div className={`${className} no-underline`}>
        <span
          className={`${
            transparentBackground ? 'bg-transparent' : 'bg-blankYellow'
          } transition-bg-1s ${className} ${classes} text-transparent`}
        >
          {children}
        </span>
      </div>
    </h2>
  );
};
export default SectionHeader;
