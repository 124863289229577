import { FC } from 'react';
import PageHeader, {
  PageHeaderProps,
} from '../components/PageHeader/PageHeader';

interface WideLayoutProps extends Omit<PageHeaderProps, 'title'> {
  children?: React.ReactNode;
  title?: string;
  pageHeaderChildren?: React.ReactNode;
  className?: string;
  h1MaxWidth?: number;
}

const WideLayout: FC<WideLayoutProps> = ({
  children,
  title,
  description,
  imgSrc,
  renderKaffepratLink,
  pageHeaderChildren,
  className,
}) => {
  return (
    <div
      className={`w-full flex flex-col items-center ${
        imgSrc && 'mb-[113px] laptop:mb-[150px]'
      }`}
    >
      {title && (
        <PageHeader
          title={title}
          description={description}
          renderKaffepratLink={renderKaffepratLink}
          imgSrc={imgSrc}
          alt={title}
          layout={` ${
            imgSrc &&
            'max-w-[1260px] pb-[0px] tablet:pb-[0px] laptop:pb-[0px] desktop:pb-[0px] mb-[18px] tablet:mb-[18px] laptop:mb-[40px] desktop:mb-[48px] mx-[20px] tablet:mx-[20px] laptop:mx-[50px] desktop:mx-[150px]'
          } ${className}`}
        >
          {pageHeaderChildren}
        </PageHeader>
      )}
      <div
        className={`w-full flex flex-col items-center px-[20px] laptop:px-[50px] desktop:px-[150px] relative [&>*]:laptop:max-w-[1260px] [&>*]:desktop:max-w-[1260px] ${className}`}
      >
        {children}
      </div>
    </div>
  );
};

export default WideLayout;
