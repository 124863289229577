import Link from 'next/link';
import { FC } from 'react';
import Body from '../Body/Body';
import SectionHeader from '../SectionHeader/SectionHeader';
import { Image, ValImage } from '@valbuild/next';

interface ImageHeroProps {
  title: string;
  imgSrc: Image;
  alt: string;
  href?: string;
  description?: string;
  header?: boolean;
  eventSeries?: string;
  layout?: string;
  extraPaddingRight?: boolean;
}

const ImageHero: FC<ImageHeroProps> = ({
  imgSrc,
  title,
  alt,
  href,
  description,
  header,
  layout,
  eventSeries,
  extraPaddingRight = false,
}) => {
  const imageOpacity = 'filter ' + !header ? 'opacity-50' : 'opacity-60';

  return (
    <div className="w-full h-[75vh] laptop:h-[85vh] desktop:h-[75vh] relative flex items-end justify-center">
      {imgSrc && (
        <div className="absolute inset-0">
          <ValImage
            src={imgSrc}
            alt={alt}
            fill
            sizes={'1200px'}
            className={`${imageOpacity} object-cover object-center`}
          />
        </div>
      )}
      <div className="relative flex flex-row justify-center w-full">
        <div
          className={`relative bottom-0 w-full ${
            !layout && 'pb-[50px] desktop:pb-[100.33px]'
          } ${layout} `}
        >
          <div className={`${extraPaddingRight && 'laptop:pr-[100px]'}`}>
            {eventSeries && (
              <div className="bg-blankBlueSecondary text-blankBlack font-solina font-[400] text-[28px] laptop:text-[40px] w-fit h-fit leading-[33.6px] laptop:leading-[35px] tracking-[1%] mb-[12px] tablet:mb-[12px] laptop:mb-[30px] desktop:mb-[30px]">
                {eventSeries}
              </div>
            )}
            {href ? (
              <Link href={href} passHref legacyBehavior>
                <div className="cursor-pointer">
                  <SectionHeader
                    className="desktop:hidden"
                    variant="plakatLarge"
                    transparentBackground
                  >
                    <a className="underline transition-50ms">{title}</a>
                  </SectionHeader>
                  <SectionHeader
                    className="hidden desktop:block"
                    variant="plakatXl"
                    transparentBackground
                  >
                    <a className="underline transition-50ms">{title}</a>
                  </SectionHeader>
                </div>
              </Link>
            ) : (
              <h1 className="font-respira font-[400] text-blankBlack tracking-[0.01em] leading-[104%] text-[44px] tablet:text-[60px] laptop:text-[100px]">
                {title}
              </h1>
            )}
            {description && <Body className="mt-[30px]">{description}</Body>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageHero;
