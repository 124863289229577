import { FC } from 'react';

interface ChapterHeaderProps {
  title: string;
}

const ChapterHeader: FC<ChapterHeaderProps> = ({ title }) => {
  return (
    <div className="flex flex-row font-plakat text-[38px] laptop:text-[48px] text-blankBlack leading-[120%] tracking-[0.01em] font-[400] uppercase py-[5px] border-b-2 border-b-blankBlack">
      {title}
    </div>
  );
};

export default ChapterHeader;
