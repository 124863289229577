import { s, c, t } from '../../val.config';
import { themeSchema } from '../schema/theme.val';

const defaultRichTextSchema = s.richtext({
  style: {
    bold: true,
    italic: true,
    lineThrough: true,
  },
  block: {
    h1: true,
    h2: true,
    h3: true,
    h4: true,
    h5: true,
    h6: true,
    ul: true,
    ol: true,
  },
  inline: {
    a: true,
    img: true,
  },
});

export type DefaultRichText = t.inferSchema<typeof defaultRichTextSchema>;

export const schema = s.object({
  title: s.string(),
  ingress: s.string(),
  chapters: s.array(
    s.object({
      header: s.string(),
      sections: s.array(
        s.object({
          header: s.string(),
          slug: s.string(),
          text: s.richtext({
            style: {
              bold: true,
              italic: true,
              lineThrough: true,
            },
            block: {
              h1: true,
              h2: true,
              h3: true,
              h4: true,
              h5: true,
              h6: true,
              ul: true,
              ol: true,
            },
            inline: {
              a: true,
              img: true,
            },
          }),
        })
      ),
      slug: s.string().raw(),
    })
  ),
  theme: themeSchema,
});

export default c.define('/content/pages/handboka.val.ts', schema, {
  chapters: [
    {
      header: `Innhold`,
      sections: [
        {
          header: `Intro`,
          slug: 'intro',
          text: [
            {
              tag: 'p',
              children: [
                'Personalhåndboken er ikke ment å være 100 % utfyllende. Har du spørsmål du ikke får svar på her kan du lene deg på følgende:',
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Be om tilgivelse heller enn tillatelse'],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Bruk Blanks penger slik du ville brukt dine egne',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Spør en kollega!'] }],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Alle ansatte kan foreslå endringer og delta i diskusjoner om våre rutiner og ordninger.',
              ],
            },
          ],
        },
      ],
      slug: 'innhold',
    },
    {
      header: `Oppdrag`,
      sections: [
        {
          header: `Kundearbeid`,
          slug: 'kundearbeid',
          text: [
            {
              tag: 'p',
              children: [
                'Alle stillinger i Blank, unntatt daglig leder, salgssjef og kontorsjef, er konsulentstillinger. Det vil si at du bruker mesteparten av tiden din i fakturerbare oppdrag - med å designe eller utvikle digitale tjenester for kundene våre.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Ute i oppdrag jobber du i team med andre fra Blank og / eller fra kunden. Vi stiller høye krav til hvem og hva vi jobber med og oppfordrer alle til å engasjere seg i valg av kunder og oppdrag.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Du er ikke selv ansvarlig for å skaffe Blank kunder eller prosjekter, men dersom du har tips eller forslag til aktuelle oppdrag for Blank er det alltid fint.',
              ],
            },
          ],
        },
        {
          header: `Egne startups`,
          slug: 'egne-startups',
          text: [
            {
              tag: 'p',
              children: [
                'I Blank bygger vi også startups på egenhånd. Er du mellom kundeoppdrag eller ønsker å utforske en idé har du mulighet til å jobbe med noen av våre interne initiativer, eller starte et nytt.\n',
                { tag: 'br' },
                { tag: 'br' },
              ],
            },
            {
              tag: 'p',
              children: [
                'Du har selv eierskap til din egen idé og bestemmer selv om du ønsker å ha med Blank i din startup, og evt hvor mye. Resten finner vi ut av sammen.',
              ],
            },
          ],
        },
        {
          header: `Hvordan vi velger oppdrag`,
          slug: 'hvordan-vi-velger-oppdrag',
          text: [
            {
              tag: 'p',
              children: [
                'Du er med og bestemmer over hvilke prosjekter og kunder du jobber for. Salgsprosessen starter med deg og dine ønsker, og deretter finner vi noe som passer. Før vi går i en dialog med en potensiell ny kunde, avklares det alltid med deg først.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Du har alltid rett til å takke nei til engasjementer. Det kan for eksempel være på grunn av en dårlig match på arbeidsoppgaver/kompetanse, tidligere erfaringer med kunde, liten interesse for domenet, osv. Du kan også takke nei til engasjementer på etisk grunnlag – dersom arbeidet strider mot din egen overbevisning – uten at du trenger å forsvare eller forklare det.',
              ],
            },
          ],
        },
        {
          header: `Salg og bemanning`,
          slug: 'salg-og-bemanning',
          text: [
            {
              tag: 'p',
              children: [
                'Salgs- og bemanningsprosesser i Blank skjer som hovedregel åpent. Hele selskapet kan se hvilke kunder vi er i dialog med og hvilke anbud vi vurderer å svare på. Det gjøres unntak, dersom kunder krever at oppdrag behandles hemmelig.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Som ansatt oppfordres du til å være med og diskutere hvilke kunder som er attraktive for oss å jobbe med, og hvilke som eventuelt ikke er det. Du oppfordres også til å si fra dersom du mener noen av kundene vi får forespørsler fra er problematiske å samarbeide med, for eksempel på grunn av etiske forhold.',
              ],
            },
          ],
        },
      ],
      slug: 'oppdrag',
    },
    {
      header: `Fagutvikling`,
      sections: [
        {
          header: `11 innedager i året`,
          slug: '11-innedager-i-aaret',
          text: [
            {
              tag: 'p',
              children: [
                'Første fredag i hver måned har vi innedag og alle i Blank møtes på loftet. Vi bruker innedagene til fagutvikling. Man styrer selv hva man bruker tiden på, du kan ha selvstudium eller være med i en gjeng. ',
              ],
            },
            {
              tag: 'p',
              children: [
                'Innedagen starter med frokost eller bakst. Vi får litt felles info før vi blir litt bedre kjent med kollegaer gjennom 10x10©-presentasjoner. Deretter har vi en kort check-in før fagutviklingstiden starter. Vi bruker ',
                {
                  tag: 'a',
                  href: 'https://trello.com/b/2Pwrza2n/fagutvikling-innedager',
                  children: [
                    {
                      tag: 'span',
                      styles: ['italic'],
                      children: ['Trelloboard innedager'],
                    },
                  ],
                },
                ' til program, organisering og deling.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                { tag: 'span', styles: ['bold'], children: ['Tema-innedager'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'To innedager i året kjører vi helt fasiliterte innedager som vi kaller tema-innedag. Da planlegger de fagansvarlige et heldagsprogram med eksterne foredragsholdere, hands-on sessions, paneldebatt og/eller workshops som alle kan delta i.',
              ],
            },
          ],
        },
        {
          header: `Kjøregår`,
          slug: 'kjoregar',
          text: [
            {
              tag: 'p',
              children: [
                '«Kjøregår» er en serie med åpne foredrag hos Blank, og alle som er nysgjerrige er hjertelig velkommen. Noen ganger starter vi innedagene våre med å invitere folk i og utenfor Blank til foredrag om spennende ting som interesserer oss holdt av noen av oss eller bra folk vi kjenner. Foredrag som har vært:',
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        '"Fra SAS SKIEN til SAS SPEKTRUM" med Silje Larsen Borgan',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['"Når det går riktig feil" med Ida Aalen'],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          header: `10x10-presentasjon`,
          slug: '10x10-presentasjon',
          text: [
            {
              tag: 'p',
              children: [
                'Hver innedag blir noen utvalgte trukket ut til å presentere enten seg selv eller sitt oppdrag. Presentasjonen skal være kort, muntlig og enkel. Formatet er 10 slides hvor du har ca 10 sekunder per slide. Målet er bare at vi skal bli litt mer kjent med deg eller oppdraget du er på. Det kan være så generelt eller spesifikt som du ønsker.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            { tag: 'p', children: ['Eksempler på tidligere presentasjoner:'] },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['10 fotballbaner jeg spilte på som barn'],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        '10 salgstriks fra min historie som juletreselger',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['10 steder jeg har bodd'] },
                  ],
                },
              ],
            },
          ],
        },
        {
          header: `Gjenger`,
          slug: 'gjenger',
          text: [
            {
              tag: 'p',
              children: [
                'En gjeng organiserer seg selv og jobber sammen i en periode for å lære mer og nye ting. De lager i tillegg noe som andre utenfor kan se/høre/delta på og få utbytte av. To ganger i året viser gjengene fram hva de har gjort.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Man finner info om gjengene på trelloboardet til innedagen. Vil du lage en ny gjeng legger du til et kort i trello med info om målet til gjengen. Vil du være med i en gjeng er det bare å ta kontakt med gjengmedlemmene.',
              ],
            },
            { tag: 'p', children: ['Eksempler:'] },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'FPLbot-gjengen lager en Slack-app for Fantasy Premier League - sjekk den ut ',
                        {
                          tag: 'a',
                          href: 'https://www.fplbot.app/',
                          children: ['her'],
                        },
                        '.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Typeteori-gjengen utforsker språk med rike typesystemer.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Pico8-gjengen lager spill med Pico8.'],
                    },
                  ],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Info om gjengene finner du på Innedag-trelloen: ',
                {
                  tag: 'a',
                  href: 'https://trello.com/b/2Pwrza2n/fagutvikling-innedager',
                  children: ['Trelloboard innedager'],
                },
              ],
            },
          ],
        },
        {
          header: `Utedager`,
          slug: 'utedager',
          text: [
            {
              tag: 'p',
              children: [
                'På utedager drar hele Blank på tur sammen et sted i Norden. Da er det litt faglig opplegg, men mest sosialt.',
              ],
            },
          ],
        },
        {
          header: `Show and tell`,
          slug: 'showandtell',
          text: [
            {
              tag: 'p',
              children: [
                'Med jevne mellomrom møtes designerne og utviklerne på loftet og har show and tell. Ansvaret går på rundgang og fokuset er faglig. Man kan f.eks. få tilbakemeldinger og innspill, fortelle om det man jobber med på oppdrag eller i fagutvikling, ha en workshop eller lignende.',
              ],
            },
          ],
        },
        {
          header: 'Kurs og konferanser',
          slug: 'konferanser',
          text: [
            {
              tag: 'p',
              children: [
                'Alle i Blank kan dra på en større fagutviklingsaktivitet i året, slik som en konferanse, et lengre kurs eller seminar. Du velger selv hva du vil gjøre og trenger egentlig ikke å avklare med andre enn kunden og teamet ditt.  Aktiviteter registreres i ',
                {
                  tag: 'a',
                  href: 'https://docs.google.com/spreadsheets/d/1LfzXYBYMoFoHhNVZx14R8kRZzIu3fvU-RV8iKe6etT8',
                  children: ['fagutviklingsoversikten'],
                },
                ' med litt info om hva det er, varighet og kostnad.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Vi har ingen absolutt kostnadsramme, og det utvises skjønn om man ønsker å delta på mer. Hovedføringen er at fagaktivitetene dine skal gagne Blank og man gjør en vurdering på evt hyppighet og totalkostnad.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Du kan bytte aktivitetsdagene vanligvis brukt til konferanser med egenlæringsdager dersom man mener man får bedre utbytte av det.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Frokosteminarer, meet-ups og lignende kommer utenom.',
              ],
            },
            {
              tag: 'p',
              children: [
                { tag: 'br' },
                'Husk å gå godkjenning fra kunde når du skal være borte, og legg inn fagutviklingsdagene i fraværskalenderen slik at forecastingen vår blir riktig.',
              ],
            },
          ],
        },
        {
          header: `Holde foredrag`,
          slug: 'holde-foredrag',
          text: [
            {
              tag: 'p',
              children: [
                'Vi vil gjerne at ansatte deler av det de kan og gjør, enten på konferanser eller interne og eksterne arrangementer. Har du en idé eller lyst til å holde foredrag, men vet ikke om hva kan du få hjelp på abstractworkshopen vår.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Hvis man holder foredrag på en konferanse, «teller» den ikke og er utenfor begrensningen på én årlig aktivitet.',
              ],
            },
          ],
        },
      ],
      slug: 'fagutvikling',
    },
    {
      header: `Lønn og betingelser`,
      sections: [
        {
          header: `Lønn`,
          slug: 'lonn',
          text: [
            {
              tag: 'p',
              children: [
                'Lønn utbetales siste arbeidsdag hver måned. En gang i året gjøres en lønnsvurdering, og eventuelle justeringer har effekt fra 1. januar. Oppdatert lønnsliste over alle ansatte finner du i Drive. Lønnen i Blank bestemmes av en utjevnet kurve basert på ',
                {
                  tag: 'a',
                  href: 'https://www.tekna.no/lonn-og-arbeidsvilkar/lonnsstatistikk/',
                  children: ['Teknas lønnsstatistikk'],
                },
                '. Vi baserer oss på øvre kvartil, og ser på både den bransjeuavhengige statistikken og på statistikken for "Data og IT".',
              ],
            },
          ],
        },
        {
          header: `Feriepenger`,
          slug: 'feriepenger',
          text: [
            {
              tag: 'p',
              children: [
                'Feriepenger utbetales på junilønnen – samtidig som man trekkes for ferie.\n',
                { tag: 'br' },
                { tag: 'br' },
              ],
            },
            {
              tag: 'p',
              children: [
                'Det skaper ofte litt forvirring, så er en kort forklaring av hvordan det funker:',
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Feriepenger for en full ferie tilsvarer 30/26 av en månedslønn. 30 = 5 uker ferie, 26 = en full månedslønn (26 virkedager). Fem uker ferie = trekk på 1 månedslønn + 4/26 deler av en månedslønn. ',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'For de som ikke har feriepenger fra i fjor, betyr det at det blir trukket en hel månedslønn pluss litt ekstra av lønnen for juli (4/26-deler).',
                      ],
                    },
                  ],
                },
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Du kan beregne junilønnen din slik:'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Månedslønn ',
                { tag: 'span', styles: ['italic'], children: ['minus'] },
                ' Månedslønn*30/26 = Ferietrekk',
              ],
            },
            {
              tag: 'p',
              children: [
                'Opptjente feriepenger ',
                { tag: 'span', styles: ['italic'], children: ['minus'] },
                ' Ferietrekk = Utbetalt i juni',
              ],
            },
          ],
        },
        {
          header: `Halv skatt i november`,
          slug: 'halv-skatt',
          text: [
            {
              tag: 'p',
              children: ['På novemberlønnen trekkes man bare halv skatt.'],
            },
          ],
        },
        {
          header: `Overtidsbetaling`,
          slug: 'overtidsbetaling',
          text: [
            {
              tag: 'p',
              children: [
                'Det betales 40 % overtidsgodtgjørelse i tillegg til ordinær timelønn for avtalt overtidsarbeid. Ordinær timelønn beregnes ved å dele brutto alminnelig årslønn på 1950 timer.   ',
              ],
            },
            {
              tag: 'p',
              children: [
                'Info om hva overtid er finner du ',
                {
                  tag: 'a',
                  href: 'https://about.blank.no/#arbeidstid',
                  children: ['her'],
                },
                '.',
              ],
            },
          ],
        },
        {
          header: `Medeierskap`,
          slug: 'medeierskap',
          text: [
            {
              tag: 'p',
              children: [
                'Når du starter i Blank får du 100 aksjer i selskapet og blir dermed medeier. I tillegg får alle tilbud om å kjøpe flere aksjer om de vil. Man må ikke kjøpe aksjer for å jobbe i Blank, men alle skal ha mulighet til det. For å skape god balanse i eierskapet får ansatte som eier mer enn 10 % av selskapet ikke tilbud om å kjøpe flere aksjer.',
              ],
            },
            { tag: 'p', children: ['Fordeler med ansatteierskap:'] },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Du får være med på eie din egen arbeidsplass.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Du får økt innflytelse. Blant annet får du en stemme per aksje på Blanks generalforsamling.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Du får ta en større del av verdiveksten i selskapet gjennom utbytte og prisstigning på aksjene.',
                      ],
                    },
                  ],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Mye tyder på at ansatteierskap fører til økt trivsel, produktivt og lønnsomhet (',
                {
                  tag: 'a',
                  href: 'https://agendamagasin.no/kommentarer/5-grunner-flere-bor-eie-egen-arbeidsplass/',
                  children: ['1'],
                },
                ', ',
                {
                  tag: 'a',
                  href: 'https://wol.iza.org/articles/does-employee-ownership-improve-performance',
                  children: ['2'],
                },
                ')',
              ],
            },
          ],
        },
        {
          header: `Kjøpe aksjer`,
          slug: 'kjope-aksjer',
          text: [
            {
              tag: 'p',
              children: [
                'Hvert halvår har du som ansatt muligheter for å kjøpe aksjer i Blank. Dersom selskapet ikke har nok aksjer til å dekke etterspørselen vil tilgjengelige aksjer fordeles likt på interesserte ansatte.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            { tag: 'p', children: ['Slik foregår det:'] },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Alle ansatte i Blank som eier mindre enn 2,0% får tilbud om å kjøpe seg opp til dette nivået.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Aksjene prises løpende til en konservativ markedspris basert på antall ansatte.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Ansatte kan kjøpe aksjer kontant eller gjennom et rentefritt lån på 3/5 G (Grunnbeløpet i folketrygden). Lånet betales tilbake gjennom lønnstrekk over 12 måneder.',
                      ],
                    },
                  ],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'I ',
                {
                  tag: 'a',
                  href: 'https://docs.google.com/spreadsheets/d/1FzH2t7B8lRx5sPLa7Ae5bSfncpxI4j0fqGmNc-w0zUw/edit?usp=sharing',
                  children: ['aksjeeierboka'],
                },
                ' finner oversikt over blant annet aksjonærer og aksjefordeling.',
              ],
            },
          ],
        },
        {
          header: `Pensjon`,
          slug: 'pensjon',
          text: [
            {
              tag: 'p',
              children: [
                'Blank sparer 5,5% av lønn mellom 0G og 12G i innskuddspensjon. Logg inn på ',
                {
                  tag: 'a',
                  href: 'http://www.gjensidige.no/',
                  children: ['gjensidige.no'],
                },
                ' med BankID dersom du ønsker å justere risikoprofilen for din pensjonssparing.\n',
                { tag: 'br' },
                { tag: 'br' },
              ],
            },
            {
              tag: 'p',
              children: [
                'Innskuddspensjonen din havner på en Egen pensjonskonto (EPK) og du kan selv velge en annen forvalter for EPKen din enn Gjensidige om du ønsker det. Du kan også samle innskuddspensjon fra tidligere arbeidsgivere (Pensjonskapitalbevis) inn på din EPK.',
              ],
            },
          ],
        },
        {
          header: `Forsikringer`,
          slug: 'forsikringer',
          text: [
            {
              tag: 'p',
              children: [
                'Her finner du en oversikt over forsikringene vi har. Informasjon om hvordan man benytter de forskjellige forsikringene ',
                {
                  tag: 'a',
                  href: 'https://docs.google.com/document/d/1p2FoxN2ZB6yZblH87y4mz1KM_uBvditi5hTvZ2oZG1A/edit',
                  children: ['finner du her'],
                },
                '.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Reiseforsikring'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Blank dekker reiseforsikring hos Gouda for deg, samboer og barn. Reiseforsikringen gjelder samboeren også når du ikke reiser med hen, da vi betaler for dekning av hele familien. Forsikringen gjelder reiser i hele verden inntil 45 dager, også fritidsreiser. Hva som dekkes av forsikringen og hva ikke ',
                {
                  tag: 'a',
                  href: 'https://docs.google.com/document/d/1p2FoxN2ZB6yZblH87y4mz1KM_uBvditi5hTvZ2oZG1A/edit',
                  children: ['finner du her'],
                },
                '.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Mobilforsikring'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Vi har en egen Reiendomsforsikring som dekker skader på mobil utover den vanlige reiseforsikringen. Max sum 10 000 kr, egenandelen er 1000 kr.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Behandlingsforsikring'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Alle ansatte har tilgang til ubegrenset medisinsk rådgivning over telefon. I tillegg dekkes, for tilfeller som det offentlige helsevesen ikke dekker eller har lang ventetid konsultasjon, operasjon, fysioterapi, rehabilitering, reise og opphold, psykologisk førstehjelp og psykolog.',
              ],
            },
            {
              tag: 'p',
              children: [
                'Gjennom forsikringen kan du også bruke legeappen Eyr til å ta legetimer gratis på mobilen. Last ned og legg inn forsikringsnummeret vårt, så slipper du å betale for konsultasjonene.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Yrkesskade- og yrkessykdomsforsikring'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Gir en engangsutbetaling i de tilfeller man pådrar seg en skade eller sykdom gjennom jobb eller reise til og fra arbeid som gjør at man ikke kan jobbe fullt eller noe i det hele tatt.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                { tag: 'span', styles: ['bold'], children: ['Uførepensjon'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'Denne forsikringen sikrer deg en månedlig utbetaling frem til du blir pensjonist og gir deg 69% av fastlønn opp til 12G minus uførepensjon fra folketrygden. I tillegg kommer 10% av 1G som en ekstra utbetaling på toppen - uavhengig av lønnsnivå.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Innskuddsfritak'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Dersom du blir minst 20% ufør, dekker denne forsikringen innbetalinger til din pensjonskonto frem til du blir 67år, utfra din lønn på det tidspunktet du ble ufør.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Forsikringer du IKKE har'],
                },
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Livsforsikring - behovet for livsforsikring varierer veldig etter livssituasjon og det er derfor ikke noe alle våre ansatte har bruk for.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Ulykkesforsikring'] }],
                },
              ],
            },
          ],
        },
        {
          header: `Internett`,
          slug: 'internett',
          text: [
            {
              tag: 'p',
              children: [
                'Blank dekker internett hjemme hos deg med en øvre grense på 750 kroner inkludert mva. i måneden.',
              ],
            },
          ],
        },
        {
          header: `Mobil`,
          slug: 'mobil',
          text: [
            {
              tag: 'p',
              children: [
                'Abonnement for mobiltelefoni dekkes av selskapet. Dette kan brukes fritt innenfor EU/EØS, med unntak av innholdstjenester dvs. kjøp av varer og tjenester via abonnementet.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Blank dekker kjøp av mobil (se arbeidsverktøy i avsnittet under) og har mobilforskring som dekker skader (se forsikringer under Lønn og betingelser).',
              ],
            },
          ],
        },
        {
          header: `Arbeidsverktøy`,
          slug: 'arbeidsverktoy',
          text: [
            {
              tag: 'p',
              children: [
                'Hva enn av utstyr eller programvare du trenger for å gjøre jobben din står du fritt til å kjøpe inn. Behovet stoler vi på at du vurderer bedre enn ledelsen. Når du starter fikser du det du trenger for å komme i gang. Vi har avtale hos bl.a. hos Komplett, brukernavn og passord finner du i 1password.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Handler du utstyr eller programvare til over 500 kr legger du det inn i ',
                {
                  tag: 'a',
                  href: 'https://docs.google.com/spreadsheets/d/1-CSM95rUG8hX7NpfgoadOCD2UNetRxyE-iI8viDjLPA/edit?usp=sharing',
                  children: ['utstyrslista'],
                },
                ' i tillegg til å utleggsføre i Tripletex.',
              ],
            },
          ],
        },
        {
          header: `Utstyrslotteri`,
          slug: 'utstyrslotteri',
          text: [
            {
              tag: 'p',
              children: [
                'Utstyr som leveres tilbake til Blank loddes ut til interesserte og selges til den heldige vinner. Er utstyret over 3 år betaler man 500 kroner for dette.',
              ],
            },
          ],
        },
        {
          header: `Fagbøker`,
          slug: 'fagboker',
          text: [
            {
              tag: 'p',
              children: [
                'Både papirbøker og elektroniske bøker kan kjøpes inn fritt. Vi har endel bøker på loftet allerede, hør på Slack eller sjekk i bokhylla først om det er noe du har lyst til å lese.',
              ],
            },
          ],
        },
        {
          header: `Databriller`,
          slug: 'databriller',
          text: [
            {
              tag: 'p',
              children: [
                'Blank dekker synsundersøkelse og standard databriller hvis du trenger det.',
              ],
            },
          ],
        },
        {
          header: `Treningsutstyr`,
          slug: 'treningsutstyr',
          text: [
            {
              tag: 'p',
              children: [
                'Blank dekker inntil 1000 kr i året til trening, det gjelder kjøp av utstyr, klær, kurs, abonnement, medlemsskap, lisens o.l. I tillegg sponser ',
                {
                  tag: 'a',
                  href: 'https://blank.no/handboka#bedriftsidrettslag',
                  children: ['Blanks Bedriftidrettslag'],
                },
                ' masse forskjellige aktiviteter dersom du er medlem og deltar med noen andre fra Blank.',
              ],
            },
          ],
        },
        {
          header: `Bysykkel`,
          slug: 'bysykkel',
          text: [
            {
              tag: 'p',
              children: [
                'Blank sponser bysykkelabonnement for alle ansatte. Hør med Knut for å få kode.',
              ],
            },
          ],
        },
        {
          header: `Ansattjubileum`,
          slug: 'ansattjubileum',
          text: [
            {
              tag: 'p',
              children: [
                'For hvert år du har vært ansatt i Blank får du en gave!',
              ],
            },
            {
              tag: 'p',
              children: [
                '1 år 400,- kinokveld ',
                { tag: 'br' },
                '\n2 år 1000,- kulturell opplevelse ',
                { tag: 'br' },
                '\n3 år 2000,- mat i fjeset ',
                { tag: 'br' },
                '\n4 år 500,- nørding i bokhandel ',
                { tag: 'br' },
                '\n5 år 5000,- en kveld i tretoppene ',
                { tag: 'br' },
                '\n6 år 1200,- ta smaksløkene på trim ',
                { tag: 'br' },
                '\n7 år 2500,- gjør en forskjell',
              ],
            },
          ],
        },
        {
          header: `Innkjøp og utlegg`,
          slug: 'utlegg',
          text: [
            {
              tag: 'p',
              children: [
                'Du står fritt til å gjøre innkjøp til Blank uten noen videre godkjenning. Som hovedregel gjelder det å bruke Blanks penger slik du ville brukt dine egne.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Utlegg utgiftsføres i ',
                {
                  tag: 'a',
                  href: 'https://tripletex.no/',
                  children: ['Tripletex'],
                },
                '. Slik gjør du det:',
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Velg ',
                        {
                          tag: 'span',
                          styles: ['italic'],
                          children: ['Reiser og utlegg'],
                        },
                        ' og laste opp kvittering. Bruker du appen kan du også trykke på ',
                        {
                          tag: 'span',
                          styles: ['italic'],
                          children: ['Utlegg'],
                        },
                        ' og ta bilde av kvitteringen.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Velg relevant kategori og skriv kort hva utlegget gjelder og evt. hvem som var med, hvis du har betalt noe til et Blank-arrangement eller lignende.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Trykk ',
                        {
                          tag: 'span',
                          styles: ['italic'],
                          children: ['Lever'],
                        },
                        ' når utlegget er klar for godkjenning.',
                      ],
                    },
                  ],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Utlegg refunderes sammen med lønnsutbetaling siste arbeidsdag i måneden og ca. den 15. hver måned.',
              ],
            },
          ],
        },
        {
          header: `Rekrutteringstips`,
          slug: 'rekrutteringstips',
          text: [
            {
              tag: 'p',
              children: [
                'Vi har ikke rekrutteringsbonus i Blank, men dersom man tipser om kandidater som ender opp med å bli ansatt spanderer Blank middag på tipseren, den nyansatte og tre andre fra Blank som en del av onboardingen.',
              ],
            },
          ],
        },
        {
          header: `Bedriftsavtaler`,
          slug: 'bedriftsavtaler',
          text: [
            {
              tag: 'p',
              children: [
                'Oversikt over bedriftsavtaler vi har finnes ',
                {
                  tag: 'a',
                  href: 'https://docs.google.com/document/d/1q3nvdwHGf_wZk-c-33mrxw5CaHQwDKnjr1YHYJXoBiM/edit?usp=sharing',
                  children: ['her'],
                },
                '.',
              ],
            },
          ],
        },
      ],
      slug: 'betingelser',
    },
    {
      header: `Arbeidstid og fravær`,
      sections: [
        {
          header: `Fleksibel arbeidstid`,
          slug: 'fleksibel-arbeidstid',
          text: [
            {
              tag: 'p',
              children: [
                'Vanlig arbeidstid i Blank er 7,5 t dagen og 37,5 t i uka. Vi har ingen kjernetid og du velger i stor grad selv når og hvor du ønsker å jobbe, men du tilpasser deg kundens rutiner.',
              ],
            },
          ],
        },
        {
          header: `Avspasering`,
          slug: 'avspasering',
          text: [
            {
              tag: 'p',
              children: [
                'Det er alltid OK å være mellom 37,5 timer i pluss eller minus på “avspaseringskontoen”. Dersom du går utenfor dette må du ta ut overtidsbetaling (dersom du har overtidsberettigede timer), avspasere eller jobbe inn/bruke ferie for å komme ajour.\n',
                { tag: 'br' },
                { tag: 'br' },
              ],
            },
            {
              tag: 'p',
              children: [
                'Skal du ta ut avspasering er det greit å avklare med kunde. Du timefører ikke avspasering.',
              ],
            },
          ],
        },
        {
          header: `Overtid`,
          slug: 'overtid',
          text: [
            {
              tag: 'p',
              children: [
                'Det er ingen forventning at du skal jobbe overtid, men om du selv vil kan du jobbe ekstra på et prosjekt. Du trenger bare passe på at det er ok for kunden (de betaler samme timepris uansett), eller for ansvarlige for internprosjektet om du jobber med det. Ved frivillig overtidsarbeid kan du velge mellom å få utbetalt ekstra lønn med overtidsgodtgjørelse eller avspasere like mange timer som du har jobbet ekstra. Hvis skulle bli pålagt eller bedt om å jobbe overtid, f.eks. hvis det trengs en liten ekstra innsats i en periode, kan du få timene utbetalt med overtidsgodtgjørelse eller avspasere 140 %. Hør med Tina hvordan du gjør det i praksis.',
              ],
            },
          ],
        },
        {
          header: `Interntid`,
          slug: 'interntid',
          text: [
            {
              tag: 'p',
              children: [
                'Man skal som hovedregel kun føre timer på interne aktivteter dersom det er avklart. Følgende aktiviteter trenger ingen avklaring:',
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Innedag (7,5t)'] }],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Konferanse/kurs og reise i den forbindelse (7,5t)',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Intervjuer og forberedelser'] },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Faginnlegg og forberedelser'] },
                  ],
                },
              ],
            },
            { tag: 'p', children: [] },
            {
              tag: 'p',
              children: [
                'Når man ikke er i prosjekt må det avklares hvis man skal jobbe mer enn 37,5 timer per uke.',
              ],
            },
          ],
        },
        {
          header: `Ferie`,
          slug: 'ferie',
          text: [
            {
              tag: 'p',
              children: [
                'Vi har 5 ukers ferie (25 dager) og i tillegg fri fra og med julaften til og med nyttårsaften. Du har rett til å ta ut full ferie selvom du ikke har jobbet deg opp feriepenger fra året før, f.eks. hvis du er nyansatt. Info om hvordan ferielønnen beregnes finner du ',
                {
                  tag: 'a',
                  href: 'https://blank.no/handboka#feriepenger',
                  children: ['her'],
                },
                '. Feriedagene dine avklares med team/kunde og deretter legges inn i ',
                {
                  tag: 'a',
                  href: 'https://inni.blank.no/calendar',
                  children: ['fraværskalenderen'],
                },
                '. Når man er på ferie fører man 7,5 timer per fulle arbeidsdag eller de timene du tar ut ferie. Har du feriedager til gode, kan opp til 10 dager overføres til påfølgende år.',
              ],
            },
          ],
        },
        {
          header: `Redusert stilling`,
          slug: 'redusert-stilling',
          text: [
            {
              tag: 'p',
              children: [
                'Ønsker du en periode å bruke mindre tid på jobb, er det mulig å redusere stillingsprosenten din eller ta midlertidig permisjon. Det kan du avtale med personallederen din.',
              ],
            },
          ],
        },
        {
          header: `Permisjon og fravær`,
          slug: 'permisjon',
          text: [
            {
              tag: 'p',
              children: [
                'Husk å informere team/kunde om fravær og legg planlagt fravær inn i ',
                {
                  tag: 'a',
                  href: 'https://inni.blank.no/calendar',
                  children: ['fraværskalenderen'],
                },
                '.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Fødsels-, svangerskaps- og barselpermisjon'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Selskapet dekker differansen mellom kompensasjon fra det offentlige, og fast lønn i selskapet.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Foreldrepermisjon påvirker ikke erfaringsbygging for lønn.',
                { tag: 'span', styles: ['bold'], children: ['Ammefri'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'Om du ammer får du opptil 1 time betalt ammefri per dag inntil barnet er 1 år.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Det er ingen øvre grense for ubetalt ammefri, har du behov for mer tid utover 1 time og/eller om barnet er over 1 år er det helt greit, bare gi beskjed til lederen din.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Repetisjonsøvelser'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Må du på repetisjonsøvelse dekker Blank differansen mellom det du får fra det offentlige, og fast lønn i selskapet.\n',
                { tag: 'br' },
                { tag: 'br' },
              ],
            },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Tilvenning i barnehage og første skoledag'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Når barnet ditt starter i barnehage eller på skole kan du ta tid for å hjelpe de på plass. Vanligvis brukes det 1 til 3 dager, men det er også mulig å bruke mer tid ved behov. Det oppfordres til å dele tilvenningstid med partner og bruke halve dager om det er mulig.',
              ],
            },
            {
              tag: 'p',
              children: [
                'Du kan selvsagt bruke tilvenningsdagene også om barnet bytter barnehage eller skole. Timene føres på Internt - Tilvenning barnehage/skole.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Dødsfall og begravelse'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Velferdspermisjon i forbindelse med dødsfall og begravelse kan føres på ',
                {
                  tag: 'span',
                  styles: ['italic'],
                  children: ['Internt - Permisjon med lønn'],
                },
                '.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                { tag: 'span', styles: ['bold'], children: ['Flyttedag'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'Du kan bruke 1 lønnet dag til flytting, det føres på ',
                {
                  tag: 'span',
                  styles: ['italic'],
                  children: ['Internt - Permisjon med lønn'],
                },
                '.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                { tag: 'span', styles: ['bold'], children: ['Legetimer'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'Legetimer, tannlegetimer og lignende kan føres på ',
                {
                  tag: 'span',
                  styles: ['italic'],
                  children: ['Internt - Permisjon med lønn'],
                },
                '.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Sykt barn og omsorgsdager'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['italic'],
                  children: ['Alt som er definert av NAV som'],
                },
                ' ',
                {
                  tag: 'a',
                  href: 'https://www.nav.no/omsorgspenger#nar-kan-du-bruke-av-omsorgsdagene',
                  children: ['omsorgsdager'],
                },
                ', samt rutinekontroller på helsestasjon er lønnet fravær. Det føres på ',
                {
                  tag: 'span',
                  styles: ['italic'],
                  children: ['Internt - Sykt barn'],
                },
                '. Du kan beregne hvor mange omsorgsdager du har rett til ',
                {
                  tag: 'a',
                  href: 'https://www.nav.no/omsorgspenger/kalkulator-antall-omsorgsdager',
                  children: ['her'],
                },
                '.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Sykdom og egenmelding'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Du har 12 egenmeldingsdager, som kan benyttes enkeltvis eller sammenhengende. Disse dagene trenger man ikke legeerklæring for. Egenmelding fører du på ',
                {
                  tag: 'span',
                  styles: ['italic'],
                  children: ['Internt - Sykdom - egenmelding.'],
                },
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Sykdom og sykemelding'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Om du har brukt opp egenmeldingsdagene, må du ha sykemelding fra lege. Tiden du er syk med sykemelding føres på ',
                {
                  tag: 'span',
                  styles: ['italic'],
                  children: ['Internt - Sykdom - sykemelding.'],
                },
              ],
            },
          ],
        },
      ],
      slug: 'fravaer',
    },
    {
      header: `Organisering`,
      sections: [
        {
          header: `Administrasjon`,
          slug: 'administrasjon',
          text: [
            { tag: 'p', children: ['Administrasjonen består av:'] },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Daglig leder - Jahn Arne Johnsen'],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Salgssjef - Knut Backer'] },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Leder for Teknologi - Magne Davidsen '],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Leder for Design - Jon Bernholdt Olsen'],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Kontorsjef - Tina Pande '] },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Fagsjef Teknologi - Ole Jacob Syrdahl '],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Fagsjef Design - Lee Frost'] },
                  ],
                },
              ],
            },
            { tag: 'h3', children: [] },
          ],
        },
        {
          header: `Styret og ansattrepresentanter`,
          slug: 'styret-og-ansattrepresentanter',
          text: [
            { tag: 'p', children: ['Styret i Blank består pt. av:'] },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Magne Davidsen - Styreleder - Rep: Majoritetsaksjonærene',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Jon Bernholdt Olsen - Styrets nestleder - Rep: Majoritetsaksjonærene',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Knut Backer - Styremedlem - Rep: Majoritetsaksjonærene',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Clara Patek - Styremedlem - Rep: Øvrige aksjonærer',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Ingrid Moen - Styremedlem - Ansattrepresentant',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Une Nordli - Styremedlem - Ansattrepresentant',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Lars Skjelbek - Varamedlem for Clara Patek (øvrige aksjonærer)',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Thea Basthus Nilsen - Varamedlem for Une Nordli (ansatte)',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Lars-Ive Gjærder - Varamedlem for Ingrid Moen (ansatte)',
                      ],
                    },
                  ],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Representanter for øvrige aksjonærer og de ansatte velges for to år av gangen.',
              ],
            },
          ],
        },
        {
          header: `Åremålsroller`,
          slug: 'aaremaalsroller',
          text: [
            {
              tag: 'p',
              children: [
                'Noen viktige ansvarsområder i Blank er fordelt på ulike åremålsroller. Det er for å sørge for at de blir godt ivaretatt og for å gi de som ønsker det mer ansvar og utviklingsmuligheter.   ',
              ],
            },
            { tag: 'p', children: [] },
            {
              tag: 'p',
              children: ['De ulike rollene og ansvarlige for 2025 er:'],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Oppstartsansvarlig - Martin Bøckman'],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Rekrutteringsansvarlig design - Lars-Ive Gjærder',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Rekrutteringsansvarlig teknologi - Simen Grini',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Salgs- og markedsstøtte - Clara Patek'],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Leder for Blank Bedriftsidrettslag - Astri Eiterstraum',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Sosialt- og arrangementsansvarlig - Sergey Jakobsen',
                      ],
                    },
                  ],
                },
              ],
            },
            { tag: 'p', children: [] },
            {
              tag: 'p',
              children: [
                'Man får aksjer for mellom 30 000,- og 50 000,- i året for ansvaret med å ha en åremålsrolle, i tillegg kan man føre overtid for arbeid ut over normal arbeidstid. Du får en egen timeføringskonto som du skal bruke til arbeidet.',
              ],
            },
          ],
        },
        {
          header: `Oppdragsansvarlig`,
          slug: 'oppdragsansvarlig',
          text: [
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Støtte salg i kundeutvikling – og innhente tilbakemeldinger fra kunden til Blank',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Følge opp og støtte Blank-konsulentene som er hos kunden',
                      ],
                    },
                  ],
                },
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: ['Som oppdragsansvarlig har man følgende oppgaver:'],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Regelmessig kontakt med salgssjef angående status hos kunde',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Halvårlige møter med alle oppdragsansvarlige for erfaringsutveksling',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Kvartalsvise prosjektsamtaler med alle Blank-konsulenter hos kunden',
                      ],
                    },
                  ],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Hvem som er oppdragsansvarlig for en kunde kan man se i ',
                {
                  tag: 'a',
                  href: 'https://inni.blank.no/projects',
                  children: ['prosjektapplikasjonen'],
                },
              ],
            },
          ],
        },
        {
          header: `Personalleder`,
          slug: 'personalleder',
          text: [
            {
              tag: 'p',
              children: [
                'Alle i Blank har en personalleder. Man velger sin egen personalleder blant personene i administrasjonen eller fagledelsen i ',
                {
                  tag: 'a',
                  href: 'https://docs.google.com/spreadsheets/d/1STB_QgMnUv9UxZKmrWJjAsL6zOgZtbqu6Y-T4ZXh1as/edit#gid=0',
                  children: ['dette dokumentet'],
                },
                '. Husk å si i fra til vedkommende. Man kan når som helst bytte.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Frem til du evt. velger en annen personalleder er det «Leder for Teknologi» som har ansvaret for teknologer, og «Leder for Design» som har ansvaret for designere.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            { tag: 'p', children: ['Personalleder følger opp din:'] },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Bemanning (engasjement og prosjekt)'],
                    },
                  ],
                },
                { tag: 'li', children: [{ tag: 'p', children: ['Lønn'] }] },
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Faglige utvikling'] }],
                },
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Generelle trivsel'] }],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Alle får et tilbud om fysisk samtale minst to ganger i året.',
              ],
            },
          ],
        },
        {
          header: `Fagleder`,
          slug: 'fagleder',
          text: [
            {
              tag: 'p',
              children: [
                'Fagleders hovedansvar er å tilrettelegge for faglig og personlig utvikling for de ansatte innen sitt fagområde. Faglederne er en del av administrasjonen og svarer til leder for design eller teknologi.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            { tag: 'p', children: ['Faglederen din har i oppgave å:'] },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Sørge for at du får den faglige veiledningen og oppfølgingen du har behov for.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Legge til rette for faglig opplegg som kurs og lignende du kan delta på om du vil.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Sørge for at du har gode rammer for å kunne fokusere på egen fagutvikling.',
                      ],
                    },
                  ],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Alle får tilbud om en fagutviklingssamtale minst to ganger i året. Faglederen tar initiativ til samtalen, men du kan selv velge hvordan den skal gjennomføres og hvem du ønsker å prate med.',
              ],
            },
          ],
        },
        {
          slug: 'amu',
          text: [
            {
              tag: 'p',
              children: [
                'AMU skal, sammen med arbeidsgiver legge til rette for at alle kan bidra til et godt felles arbeidsmiljø, som i Blank er å etterleve prinsippene tillit, åpenhet og fellesskap',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            { tag: 'p', children: ['Representanter fra arbeidsgivers side:'] },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Jahn Arne Johnsen'] }],
                },
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Knut Backer'] }],
                },
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Tina Pande - Varamedlem'] },
                  ],
                },
              ],
            },
            { tag: 'p', children: ['Representanter fra arbeidstakers side:'] },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Ingrid Moen - Hovedverneombud'] },
                  ],
                },
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Une Nordli'] }],
                },
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Lars-Ive Gjærder - Varamedlem'] },
                  ],
                },
              ],
            },
          ],
          header: 'Arbeidsmiljøutvalg (AMU)',
        },
      ],
      slug: 'organisering',
    },
    {
      header: `Loftet`,
      sections: [
        {
          header: `Bruk av loftet`,
          slug: 'bruk-av-loftet',
          text: [
            {
              tag: 'p',
              children: [
                'I Blank jobber vi tett med kundene våre og sitter vanligvis sammen med de, men har vi mulighet sitter vi gjerne på kontoret vårt - loftet. Vi har jobbet mye for å gjøre loftet til et behagelig sted hvor du skal ha lyst å være. Her har vi alt vi trenger for å lage bra greier, jobbe sammen eller alene og ha faglige arrangementer og fester. På kjøkkenet er det alltid god kaffe, drikke og snacks.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Bruk av loftet er ganske selvforklarende, men om du lurer på noe kan du ta en titt på ',
                {
                  tag: 'a',
                  href: 'https://docs.google.com/document/d/1KfxwuWTmriCPuk_uhgIs_n3etA-ab-_uESHwuTZdD_k/edit#heading=h.iv37hw93zicf',
                  children: ['En guide til loftet'],
                },
                '.',
              ],
            },
          ],
        },
        {
          header: `Tøfler`,
          slug: 'tofler',
          text: [
            {
              tag: 'p',
              children: [
                'For å holde det rent og trivelig på loftet har vi en innesko/tøffel/sokkelesten-policy. Alle kan kjøpe seg et par tøfler til å ha på Loftet for inntil 600,- og utgiftsføre. Vi har en liten kasse med lånetøfler til gjester og de som har glemt.',
              ],
            },
          ],
        },
        {
          header: `Ha med gjester`,
          slug: 'ha-med-gjester',
          text: [
            {
              tag: 'p',
              children: [
                'Man står fritt til å bruke møterommene og loftet til workshops og samarbeid med kunder. Venner og gjester av ansatte er også velkommen til å låne en pult for en dag eller to såfremt vi har ledig plass, man må bare skrive under taushetserklæring som lastes opp på drive.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Gjester kan benytte besokhosblank-nettverket, og Blank-ansatte kan booke møterom og området de trenger via kalenderen.',
              ],
            },
          ],
        },
        {
          header: `Utenfor arbeidstid`,
          slug: 'utenfor-arbeidstid',
          text: [
            {
              tag: 'p',
              children: [
                'Det er bare hyggelig dersom ansatte ønsker å bruke kontoret utenfor normal arbeidstid, enten som arbeidsplass eller til sosialisering. Den viktigste regelen er at man etterlater kontoret rent og ryddig.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Om du vil bruke kontoret til et eget arrangement gjelder følgende:',
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Spør på Slack (#loftet) om det er OK.'],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Lag en hendelse i Google Calendar og legg til Loftet.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Skaff egen mat og drikke. Benytt helst ikke varer kjøpt inn til Blank, ihvertfall ikke uten å erstatte de.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Vurder om det er nødvendig å avtale ekstra vask og renhold før kontoret skal brukes til jobb.',
                      ],
                    },
                  ],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Alle ansatte har lik rett til å bruke kontoret, og «førstemann til mølla» gjelder dersom man ønsker å bruke hele kontoret alene.',
              ],
            },
          ],
        },
        {
          header: `Mat og drikke`,
          slug: 'mat-og-drikke',
          text: [
            {
              tag: 'p',
              children: [
                'Om du benytter loftet sosialt sammen med kolleger, f.eks. filmkveld eller fredagspils, kan dere forsyne dere alt av mat og drikke som finnes på loftet, eller kjøpe inn og utgiftsføre.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Det er også helt greit å ta med venner i uformelle sammenhenger. Spiser og drikker dere da for mer enn ca 500 kr bør det erstattes. Du kan enten kjøpe inn tilsvarende du har brukt eller høre om innkjøpsansvarlig kan bistå og få det trukket av lønna. Gjør det så raskt som mulig, så vi ikke går tom.',
              ],
            },
          ],
        },
        {
          header: `Vask`,
          slug: 'vask',
          text: [
            {
              tag: 'p',
              children: [
                'Kontoret vaskes på kveldstid to ganger i uken, se tidspunkt i loft-kalenderen. Ta kontakt med Tina om du trenger å flytte eller bestille ekstra vask.',
              ],
            },
          ],
        },
        {
          header: `Utstyr på loftet`,
          slug: 'utstyr',
          text: [
            {
              tag: 'p',
              children: [
                { tag: 'span', styles: ['bold'], children: ['Printer'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'I skapet ved siden av store møterom står det en printer/kopimaskin/scanner som printer i A4. Den funker når du er koblet til hjemmehosblank-nettverket.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                { tag: 'span', styles: ['bold'], children: ['3D-printer'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'Vi har en Prusa 3D-printer som det selvfølgelig er fritt frem for alle å bruke. Det finnes en liten oppstartsguide ',
                {
                  tag: 'a',
                  href: 'https://docs.google.com/document/d/1qxc_b4R94w1YC0-eBi2tNAUb1rGHqKqYe2wqes5c4F4',
                  children: ['her'],
                },
                '.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                { tag: 'span', styles: ['bold'], children: ['AV-utstyr'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'Når man er koblet til det trådløse nettet kan man spille til høyttalerne med Spotify Connect. For å stille volumet til høyttalerne bruker man appen ',
                { tag: 'span', styles: ['italic'], children: ['Spark'] },
                ' fra Devialet.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                { tag: 'span', styles: ['bold'], children: ['PA-anlegg'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'Vi har en DJ-mixer som er koblet rett til PA-anlegget.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [{ tag: 'span', styles: ['bold'], children: ['VR'] }],
            },
            {
              tag: 'p',
              children: [
                'Vi har VR-briller, og en egen PC med en rekke spill. Passordet til PCen finner du i 1Password. Dersom du har problemer med å komme i gang, spør på Slack.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                { tag: 'span', styles: ['bold'], children: ['Tappetårn'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'I ',
                {
                  tag: 'a',
                  href: 'https://docs.google.com/document/d/1KfxwuWTmriCPuk_uhgIs_n3etA-ab-_uESHwuTZdD_k/edit#heading=h.sy8dcmnsvotr',
                  children: ['En guide til loftet'],
                },
                ' finner du også en guide til bruk av tappetårnet - hvordan du kobler fra og til fat og rengjør. Loggfør renhold og andre praktiske hendelser med tappetårnet på Google Drive ',
                {
                  tag: 'a',
                  href: 'https://drive.google.com/open?id=12dMDQAyuNo45Sak9J4j5F3i7CQ672nbq9CNFzQ4Zzmw',
                  children: ['her'],
                },
                '.',
              ],
            },
          ],
        },
      ],
      slug: 'loftet',
    },
    {
      header: `Sosialt`,
      sections: [
        {
          header: `Sosialt i Blank`,
          slug: 'sosialt-i-blank',
          text: [
            {
              tag: 'p',
              children: [
                'Vi liker å gjøre ting sammen og Blank arrangerer flere mer eller mindre faste arrangementer. Vi støtter gjerne sosiale aktiviteter, det er bare å starte ting så lenge det er åpent for alle. Når du joiner Blank-slacken blir du invitert til alle kanalene for sosiale aktiviteter og velger selv hva du vil være med på. Under finner du en oversikt over noen av aktivitetene vi har.',
              ],
            },
          ],
        },
        {
          header: `Fester og sånt`,
          slug: 'fest',
          text: [
            {
              tag: 'p',
              children: [
                'I løpet av året har vi flere fester og arrangementer. Vi drar blant annet på Øyafestivalen sammen, feirer julebord, julelunsj og juletrefest (for Blank-barn), vi har lønningspils, og sommerfest. Vi liker å få besøk og inviterer venner og bransjefolk til f.eks. studentfest, bursdagfest og vors. Har noen en god idé til en fest eller noe annet gøy heier vi på og støtter det, "Is på tusen vis"-temakvelden eller Juleølsmakingen er noen eksempler.',
              ],
            },
          ],
        },
        {
          header: `Pizzabot`,
          slug: 'pizzabot',
          text: [
            {
              tag: 'p',
              children: [
                'Pizzaboten inviterer folk på pizza, og når fem stk har svart ja til pizza på satt dato, postes det i #pizza. Så er det bare å glede seg til pizza.',
              ],
            },
          ],
        },
        {
          header: `Middagsklubb`,
          slug: 'middagsklubb',
          text: [
            {
              tag: 'p',
              children: [
                'Middagsklubben er en ganske lavterskel aktivitet hvor én person inviterer til middag hjemme hos seg, kommer opp med hva som skal lages den kvelden, og handler inn matvarer og drikke. Vi lager maten og spiser sammen. Blank dekker utgiftene.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Den som hoster kan enten sette en dato, eller ha en avstemning i #middagsklubben. Si fra hvor mange du har plass til, samme om det er to, seks eller 20 stk – så er det first come, first serve av de som har anledning til å være med den satte datoen.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Dersom man synes det er litt pes å stå for alt fra vertskap til innkjøp og å komme opp med meny, er det selvfølgelig fritt frem for å slå seg sammen med flere.',
              ],
            },
          ],
        },
        {
          header: `FIFA-turnering`,
          slug: 'fifa-turnering',
          text: [
            {
              tag: 'p',
              children: [
                'FIFA-turnering arrangeres med ujevne mellomrom. Alle kan invitere med venner og kjente.',
              ],
            },
          ],
        },
        {
          header: `Filmklubb`,
          slug: 'filmklubb',
          text: [
            {
              tag: 'p',
              children: [
                'Folk i Blank lager popcorn og ser på en kul film sammen på loftet. Se #filmklubb på Slack for å avtale når og hva som vises.',
              ],
            },
          ],
        },
        {
          header: `Telttur`,
          slug: 'telttur',
          text: [
            {
              tag: 'p',
              children: [
                'Vann og dann hender det at noen av oss tar seg en telt- og/eller hengekøyetur. Se #telttur for datoer.',
              ],
            },
          ],
        },
        {
          header: `Skitur`,
          slug: 'skitur',
          text: [
            {
              tag: 'p',
              children: [
                'De siste to årene har vi leid hytte og dratt på skitur med en gjeng folk. Det er åpent både fordi som vil stå nedover, gå bortover, eller som bare vil spise mat og spille yatzy. Nye skiturer annonseres i #general.',
              ],
            },
          ],
        },
        {
          header: `Vinklubben`,
          slug: 'vinklubben',
          text: [
            {
              tag: 'p',
              children: [
                '1 person hoster og bestemmer tema for vinkveld. Alle som er med tar med en flaske som passer til tema. Info i #vinklubben.',
              ],
            },
          ],
        },
        {
          header: `Kunst- og håndverkskveld`,
          slug: 'kunst-og-haandverkskveld',
          text: [
            {
              tag: 'p',
              children: [
                'En kveld for de som syr, strikker, maler, spikker, tegner, bygger, fikser, trykker eller vil lære i Blank. Vi tar med prosjektene våre og henger sammen på loftet. Info i #kunst-og-håndverk.',
              ],
            },
          ],
        },
      ],
      slug: 'sosialt',
    },
    {
      header: `Bedriftsidrettslag`,
      sections: [
        {
          header: `Bedriftsidrettslag`,
          slug: 'bedriftsidrettslag-2',
          text: [
            {
              tag: 'p',
              children: [
                'Vi har et bedriftsidrettslag som har som mål å fremme aktivitet, trening og trivsel. Vi arrangerer faste ukentlige aktiviteter, som squash og løpeturer, samt andre aktiviteter som avtales etter behov, for eksempel klatring, yoga og pilates. Tidligere har vi også deltatt på tenniskurs, prøvd skiskyting og hatt førstehjelpskurs.\n',
                { tag: 'br' },
                { tag: 'br' },
              ],
            },
            {
              tag: 'p',
              children: [
                'Hvis du har forslag til andre aktiviteter du ønsker å gjøre, er det bare å ta kontakt med lederen for bedriftsidrettslaget, så ser vi hva vi kan få til.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Årsavgiften er 100 kr, og den kan betales via Vipps til BLANK BEDRIFTSIDRETSSLAG (#558110).',
              ],
            },
          ],
        },
        {
          header: `Løping`,
          slug: 'loping',
          text: [
            {
              tag: 'p',
              children: [
                'Flere av oss i Blank liker å løpe korte, rolige eller lange, raske turer. Uansett om du vil konkurrere mot Strava-botens lister i #random hver mandag eller slå din egen tid fra i fjor, er det alltid gøy å ha et mål å trene mot. Derfor melder vi oss hvert år på mosjonsløp som Sentrumsløpet, Holmenkollstafetten, Fornebuløpet, Nøklevann Rundt og Oslo Maraton. Gi beskjed hvis det er et løp du ønsker å delta på, så ordner vi med påmeldingskode og bankett. Hver mandag er det også en gruppe som løper en kort runde i sentrum før jobb, og de vil gjerne ha med flere på turen.',
              ],
            },
          ],
        },
        {
          header: `Squash`,
          slug: 'squash',
          text: [
            {
              tag: 'p',
              children: [
                'Blank spiller squash på ',
                {
                  tag: 'a',
                  href: 'http://www.sqf.no/sentrum-squash-fitness/',
                  children: ['Sentrum Squash & Fitness'],
                },
                ' på onsdager 17–18. Utstyr kan leies på stedet, eller kanskje noen andre i Blank har utstyr til utlån. Følg med i kanalen #bil-squash for mer informasjon og for å melde deg på. Hvis du vil samle en gjeng fra jobben og spille på andre tidspunkter, er det mulig å booke bane som bedriftsbruker, innloggingsinformasjon finner du i 1password.',
              ],
            },
          ],
        },
        {
          header: `Tennis og Padel`,
          slug: 'tennis-og-padel',
          text: [
            {
              tag: 'p',
              children: [
                'Vi har avtale med Oslo Tennisarena på Hasle, der vi kan booke både tennis- og padelbaner. Sjekk kanalene #bil-tennis eller #bil-padel for å finne noen å spille med. Bedriftsbruker finner du i 1password.',
              ],
            },
          ],
        },
        {
          header: `Esport`,
          slug: 'esport',
          text: [
            {
              tag: 'p',
              children: [
                'Kanalen #bil-esport har en bot som hver andre uke sjekker om det er interesse for å booke et rom på Eldorado Gaming. Hvis det er interesse, booker vi rom, og deltakerne bestemmer selv hvilke spill de vil spille og hvordan de vil organisere det.',
              ],
            },
          ],
        },
        {
          header: `Klatring`,
          slug: 'klatring',
          text: [
            {
              tag: 'p',
              children: [
                'Blank klatrer vanligvis på tirsdager klokken 07:00. Blank har avtale med Klatreverket. Vis ID i resepsjonen, så får du et personlig kort som du kan bruke når det er to eller flere fra Blank som er med og klatrer. Finn klatrepartnere på #bil-klatring, og husk at som med alle andre Blank-aktiviteter er terskelen ekstremt lav.',
              ],
            },
          ],
        },
        {
          header: `Yoga og pilates`,
          slug: 'yoga',
          text: [
            {
              tag: 'p',
              children: [
                'I blant gjør vi yoga eller pilates sammen. Vi har en avtale med Kjernekraft Oslo som holder timer. Vanligvis avtaler vi hvilken time vi vil gå på i #bil-yoga-pilates. For å bli med oppretter man en personlig konto hos Kjernekraft og melder seg på timen selv. Informasjon om oppretting av konto og påmelding finnes pinnet i #bil-yoga-pilates. Her gjelder også regelen om at man må være minst 2 stykker.',
              ],
            },
          ],
        },
        {
          header: `Stravaboten`,
          slug: 'stravaboten',
          text: [
            {
              tag: 'p',
              children: [
                'Hver mandag kl 8 sender Stravaboten en melding til #random i Slack med sist ukes treningstopplister. For å dukke opp i lista må du melde deg inn i tilhørende klubb på Strava:',
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        {
                          tag: 'a',
                          href: 'https://www.strava.com/clubs/blank',
                          children: ['Løping'],
                        },
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        {
                          tag: 'a',
                          href: 'https://www.strava.com/clubs/blank-pedal',
                          children: ['Sykling'],
                        },
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        {
                          tag: 'a',
                          href: 'https://www.strava.com/clubs/blanke-ski',
                          children: ['Ski'],
                        },
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        {
                          tag: 'a',
                          href: 'https://www.strava.com/clubs/blankwalking',
                          children: ['Gåing'],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Hvis du ikke dukker opp i lista til tross for at du er medlem av den respektive klubben er nok årsaken at du har en ikke-offentlig profil. Da må Stravabotens medhjelper, ',
                {
                  tag: 'a',
                  href: 'https://www.strava.com/athletes/37800142',
                  children: ['Speed Freak'],
                },
                ', følge deg på Strava. Si ifra til Lars Skjelbek, så fikser han det. Eventuelt logg inn på Strava med Speed Freak-kontoen og følg deg selv (brukernavn og passord ligger i Blanks 1password).',
              ],
            },
          ],
        },
        {
          header: `Utstyr`,
          slug: 'utstyr',
          text: [
            {
              tag: 'p',
              children: [
                'Blank BIL har også diverse utstyr til utlån, blant annet for frisbeegolf, croquet, kubb, tennis, fotball, osv. Hvis det er behov for mer utstyr, kan vi kjøpe inn det.',
              ],
            },
          ],
        },
      ],
      slug: 'bedriftsidrettslag',
    },
    {
      header: `Bærekraft`,
      sections: [
        {
          header: `Bærekraft i Blank`,
          slug: 'baerekraft-i-blank',
          text: [
            {
              tag: 'p',
              children: [
                'Blank ønsker å bidra til en bærekraftig utvikling innenfor:',
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Likestilling og mangfold'] },
                  ],
                },
                { tag: 'li', children: [{ tag: 'p', children: ['Økonomi'] }] },
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Miljø og klima'] }],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Vi vet at vi bare er et lite norsk selskap, men ønsker å bidra hvor vi kan og være til inspirasjon. Vi er genuint opptatt av bærekraft og det er viktig for oss å være konkrete og ærlige på hvilke tiltak og ordninger vi har, og tankegangen bak disse.',
              ],
            },
          ],
        },
        {
          header: `Likestilling og mangfold`,
          slug: 'likestilling',
          text: [
            {
              tag: 'p',
              children: [
                'Flere deler av Blanks grunnfilosofi og prosesser er ment for å minimere ulikheter basert på kjønn og bakgrunn. Vårt mål er å bygge et selskap der alle føler de er involvert og har lik mulighet for å bidra, skape og engasjere seg.   ',
              ],
            },
            {
              tag: 'p',
              children: [
                'Vi følgende ordninger for å bl.a. å redusere ulikhet:',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                { tag: 'span', styles: ['bold'], children: ['Åpen lønn'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'Åpen lønn og felles lønnsutvikling basert på erfaring skaper likhet og felles forventninger på tvers av kjønnene. Vi trekker ikke foreldrepermisjon fra erfaringsbyggingen i lønn.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Representasjon i rekruttering'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Kvinnelige utviklere og mannlige designere prioriteres inn når vi rekrutterer aktivt selv. Vi vil øke representasjon i ansettelsesprosessene, men prosessen er lik for alle og vi kvoterer ikke.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Investeringsmuligheter'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Vi har like muligheter for alle og flere støtteordninger for at ansatte skal kunne investere i selskapet.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Kulturelt mangfold'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Blanks største utfordring når det gjelder kulturelt mangfold er at vi har norsk som arbeidsspråk, og stiller krav om gode norskferdigheter. Dette gjør at mange dyktige utviklere og designere med bakgrunn fra andre land enn Norge ikke blir vurdert.  ',
              ],
            },
            {
              tag: 'p',
              children: [
                'Vi ønsker å bedre dette ved å gi støtte til norskkurs til de som har et godt utgangspunkt, men ønsker å etablere språket enda bedre.',
              ],
            },
          ],
        },
        {
          header: `Økonomi`,
          slug: 'okonomi',
          text: [
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Bærekraftig og trygg økonomi'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Selskapet drives økonomisk bærekraftig og trygge ansettelsesforhold.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Gode arbeidsforhold'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Blank er opptatt av gode arbeidsforhold for de ansatte, de aller fleste beslutninger tas med utgangspunkt i den ansatte. Vi ønsker at flest mulig som direkte påvirkes av en beslutning også deltar i prosessen.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Etikk i salgsprosesser'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Den største påvirkningsmuligheten Blank har som selskap ligger i arbeidet vi gjør for kundene våre. Fordi vår salgs- og bemanningsprosess er åpen er det mulig for alle ansatte å komme med innspill med tanke på etikk og bærekraft i kunder og oppdrag som vurderes.',
              ],
            },
            {
              tag: 'p',
              children: [
                'Dersom prosessen holdes hemmelig etter spesielt ønske fra kunde eller ved sensitive prosjekter skal den etiske siden av oppdraget vurderes av minst en person i administrasjonen utover salgsansvarlig.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Medbestemmelsesrett'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Dine etiske vurderinger er gyldig grunn til å ikke ville jobbe for en kunde. Vi har diskusjoner på selskapsnivå i vurderingen av oppdrag, men er forsiktige med å lage generelle retningslinjer for hvilke kunder/bransjer vi skal jobbe for. Den enkeltes mulighet til å bestemme er viktig for oss og vi ønsker at disse vurderingene skal tilhøre de som på tidspunktet jobber i Blank.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Registrering av bærekraftsmål'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Alle prosjekter som opprettes i internsystemene våre kan tagges med relevante underkategorier fra FNs bærekraftsmål. Det er ikke et krav om at prosjektene vi gjør er knyttet til bærekraftsmål, men ved å tagge prosjektene har vi mulighet til å se effekten av arbeidet vi gjør.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                { tag: 'span', styles: ['bold'], children: ['Veldedighet'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'Blank bidrar økonomisk til veldedighet hvert år, for å gi tilbake til andre deler av samfunnet som ikke har samme muligheter som oss. Eksempler på mottakere av slike gaver er: WWF, CARE, Kirkens Bymisjon, Nerdaid, Fattighuset, Ocean Cleanup, Flyktninghjelpen mv.',
              ],
            },
          ],
        },
        {
          header: `Miljø og klima `,
          slug: 'miljo',
          text: [
            {
              tag: 'p',
              children: [
                { tag: 'span', styles: ['bold'], children: ['Kontormiljø'] },
              ],
            },
            {
              tag: 'p',
              children: [
                'Som et konsulentselskap som stort sett leverer digitalt og har de fleste ansatte sittende ute hos kunde, har kontoret vårt liten påvirkning på ytre miljø. Det er for øvrig lagt opp til kildesortering, selskapet har ikke papirarkiv og det er ellers lite bruk av papir og utskrifter.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Unngå overforbruk'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Blank bruker som hovedregel ikke budsjetter eller rammer - hverken på selskapsnivå eller ansattnivå. Dette fordi det ofte kan føre til ekstra forbruk for å «bruke opp rammen» uten at det dekker et reelt behov. Vi ønsker å kjøpe utstyr når vi trenger det - og som varer lenge.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Begrenset utskifting av utstyr'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Blank har ikke regelmessig utskifting av utstyr, men utstyret vårt byttes først ut når det trengs. Brukt utstyr leveres tilbake til Blank og gjenbrukes, loddes ut til de ansatte eller doneres. Slik sikrer vi at gammelt og brukbart utstyr ikke kasseres unødvendig.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Bærekraftighet i arbeidsreiser'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Blank oppfordrer til å vurdere alternative reisemåter dersom tid- og avstand tillater det. Dette er opp til hver enkelt. Om man kan jobbe på reisen må man gjerne velge tregere reisemåter.  Alle ansatte oppfordres også til å klimakompensere for flyreiser.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Sykkeltransport'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Til reising i Oslo oppfordres de ansatte til bruk av sykkel og Blank kjøper hvert år abonnement på Oslos bysykkel-løsning til hver ansatt.  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Vegetariske alternativer'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Det er alltid mulig å velge vegetar-varianter på arrangementene våre.',
              ],
            },
          ],
        },
      ],
      slug: 'baerekraft',
    },
    //
    {
      header: 'Varsling',
      sections: [
        {
          header: 'Om varsling',
          slug: 'om-varsling',
          text: [
            {
              tag: 'p',
              children: [
                'Varsling er at vi sier ifra om kritikkverdige forhold til noen som kan gjøre noe med det.',
              ],
            },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['italic'],
                  children: [
                    'Med kritikkverdige forhold menes forhold som er i strid med',
                  ],
                },
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Lover og regler (rettsregler)'] },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Skriftlige etiske retningslinjer'],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Etiske normer som det er bred tilslutning til i samfunnet.',
                      ],
                    },
                  ],
                },
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Lovbrudd og straffbare forhold anses alltid som kritikkverdige forhold.',
              ],
            },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['italic'],
                  children: ['Eksempler på kritikkverdige forhold er'],
                },
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Fare for liv eller helse'] },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Korrupsjon eller annen økonomisk kriminalitet, myndighetsmisbruk ',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Uforsvarlig arbeidsmiljø'] },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Brudd på personopplysningssikkerheten'],
                    },
                  ],
                },
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Vi har en fullstendig varslingsprosedyre med mer informasjon ',
                {
                  tag: 'a',
                  href: 'https://docs.google.com/document/d/1XOSHjKzYs_eHSaKOFrvmsyYBUyfKcm3ikalcUA4Dvb8/edit',
                  children: ['her'],
                },
                ' - den ble satt opp i AMU basert på et oppsett fra ',
                {
                  tag: 'a',
                  href: 'https://www.arbeidstilsynet.no/varslingsrutinen/',
                  children: ['Arbeidstilsynet'],
                },
                '. ',
              ],
            },
          ],
        },
        {
          header: 'Varslingsplikt',
          slug: 'varslingsplikt',
          text: [
            {
              tag: 'p',
              children: [
                'Noen ganger har vi plikt til å varsle. Det gjelder dersom vi blir:',
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Oppmerksomme på feil eller mangler som kan medføre fare for liv eller helse, og som ansatte ikke selv kan rette opp',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Kjent med at det skjer trakassering eller diskriminering på arbeidsplassen',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Skadet eller syke av arbeidet eller forholdene på arbeidsplassen',
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          header: 'Hvordan varsle',
          slug: 'hvordan-varsle',
          text: [
            {
              tag: 'p',
              children: [
                'Vi ønsker først at du varsler til nærmeste leder (oppdragsansvarlig, personalleder, daglig leder).',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Dersom du vil ha støtte til å håndtere saken kan du kontakte verneombud eller noen andre i AMU. Varselet kan gis i det formatet den ansatte ønsker. For eksempel muntlig, over slack, e-post eller lignende.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Gjelder varselet øverste leder kan du varsle verneombud/AMU eller styreleder.',
              ],
            },
          ],
        },
        {
          slug: 'anonyme-varsler',
          text: [
            {
              tag: 'p',
              children: [
                'Hvis du ønsker å varsle anonymt kan du (inntil vi har et skjema på plass) printe ut varselet og legge det i skapet til den du ønsker å varsle til. ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Husk at det kan være vanskelig å løse noe uten mulighet til å prate med den som varsler. Hos Verneombud/AMU  kan du alltid få støtte hvis det er noe du syns er vanskelig å varsle om.',
              ],
            },
          ],
          header: 'Anonyme varsler',
        },
      ],
      slug: 'varsling',
    },
    {
      header: `Nyttig å vite`,
      sections: [
        {
          header: `Endre innhold`,
          slug: 'endre-innhold',
          text: [
            {
              tag: 'p',
              children: [
                'Dersom du ønsker å bidra ved å endre eller legge til innhold på ',
                { tag: 'a', href: '//blank.no', children: ['blank.no'] },
                ' kan du gjøre dette i ',
                {
                  tag: 'a',
                  href: 'https://www.blank.no/api/val/authorize?redirect_to=https://www.blank.no/api/val/enable?redirect_to=https://blank.no/val',
                  children: ['Val'],
                },
                '. Du får tilgang til Val av Magne.',
              ],
            },
          ],
        },
        {
          header: `Oversikt over internsystemer`,
          slug: 'internsystemer',
          text: [
            {
              tag: 'p',
              children: [
                'Du får tilgang på alle nødvendige internsystemer ved oppstart, hvis du mangler noe spør på Slack.',
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        {
                          tag: 'a',
                          href: 'https://inni.blank.no',
                          children: ['inni.blank.no'],
                        },
                        ' for timeføring, overtid, fraværskalender, ansattliste, KPI m.m.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        {
                          tag: 'a',
                          href: 'https://tripletex.no/execute/login',
                          children: ['Tripletex'],
                        },
                        ' for å lage utlegg og finne lønnsslipper. ',
                        {
                          tag: 'a',
                          href: 'https://www.tripletex.no/tripletex-pa-mobil/',
                          children: ['Last ned appen'],
                        },
                        ' for tilgang på mobil.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        {
                          tag: 'a',
                          href: 'https://slack.com/',
                          children: ['Slack'],
                        },
                        ' for kommunikasjon.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        {
                          tag: 'a',
                          href: 'https://trello.com/w/ncx35',
                          children: ['Trello'],
                        },
                        ' for program på innedagene og for å følge med på bemanning og salg, byråkratiet og årshjul.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Drive for lagring.'] }],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        {
                          tag: 'a',
                          href: 'https://blank.cvpartner.com/',
                          children: ['CV Partner'],
                        },
                        ' for å oppdatere CV.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        {
                          tag: 'a',
                          href: 'https://app.recruitee.com/#/dashboard/overview',
                          children: ['Recruitee'],
                        },
                        ' for håndtering av rekruttering.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        '1Password for håndtering av passord og felleskontoer.',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        {
                          tag: 'a',
                          href: 'https://dashboard.robinpowered.com/loftet/login',
                          children: ['Robin'],
                        },
                        ' for å booke plass på loftet. ',
                        {
                          tag: 'a',
                          href: 'https://robinpowered.com/downloads',
                          children: ['Last ned appen'],
                        },
                        ' for tilgang på mobil.',
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          header: `Jahn Arnes ordliste`,
          slug: 'ordliste',
          text: [
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Faktureringsgrad'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Faktureringsgrad (FG) benyttes både i forbindelse med forecasting for bemanning, og i forbindelse med rapportering. Faktureringsgrad beregnes på følgende måte:  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Faktureringsgrad = Fakturerbare timer / Tilgjengelige timer  ',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Tilgjengelige timer'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Alle timer som er tilgjengelig for arbeid på selskapsnivå, det vil si timene det betales lønn for. Tilgjengelige timer i en periode beregnes på følgende måte:',
              ],
            },
            {
              tag: 'p',
              children: [
                'Tilgjengelige timer = Arbeidsdager i perioden * Ansatte i perioden * 7,5 - Fratrekk',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            { tag: 'p', children: ['Følgende trekkes fra:'] },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: ['Helligdager (faktiske og selskapsbestemte)'],
                    },
                  ],
                },
                { tag: 'li', children: [{ tag: 'p', children: ['Ferie'] }] },
                {
                  tag: 'li',
                  children: [
                    {
                      tag: 'p',
                      children: [
                        'Permisjon uten lønn (inkludert foreldrepermisjon)',
                      ],
                    },
                  ],
                },
                {
                  tag: 'li',
                  children: [
                    { tag: 'p', children: ['Sykdomsfravær med sykemelding'] },
                  ],
                },
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Følgende trekkes ikke fra tilgjengelige timer, selv om man er fraværende:',
              ],
            },
            {
              tag: 'ul',
              children: [
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Sykdom - egenmelding'] }],
                },
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Sykt barn'] }],
                },
                {
                  tag: 'li',
                  children: [{ tag: 'p', children: ['Avspasering'] }],
                },
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Denne beregningen er ikke helt nøyaktig, da vi ved sykemelding faktisk betaler lønn i arbeidsgiverperioden. I tillegg betaler vi et mellomlegg mellom Folketrygden og ansattes lønn ved foreldrepermisjon.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Fakturerbare timer'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Fakturerbare timer er alle timer som bemannes eller føres på fakturerbare timeføringskoder.',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                {
                  tag: 'span',
                  styles: ['bold'],
                  children: ['Oppnådd timepris'],
                },
              ],
            },
            {
              tag: 'p',
              children: [
                'Oppnådd timepris (OT) betyr snittpris per time solgt i en viss periode. Dette kan regnes ut totalt for selskapet, eller for en spesifikk kunde. Oppnådd timepris beregnes slik:',
              ],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: ['Oppnådd timepris = Total omsetning / Antall timer'],
            },
            { tag: 'p', children: [{ tag: 'br' }] },
            {
              tag: 'p',
              children: [
                'Merk at den totale omsetningen, deles på antall timer i fakturerbare prosjekter. Dersom man for eksempel rabatterer eller avskriver timer, vil dette påvirke oppnådd timepris.',
              ],
            },
          ],
        },
      ],
      slug: 'nyttig-aa-vite',
    },
  ],
  ingress: 'Her finner du det meste du trenger å vite om å jobbe i Blank.',
  theme: `dark`,
  title: `Personalhåndbok`,
});
